import Dashboard from '../pages/dashboard/Dashboard';
import Training from '../pages/TrainingPage';
import Evaluation from '../pages/EvaluationPage';
import Login from '../pages/LoginPage';
import EmailConfirmation from '../pages/EmailConfirmationPage';

import MobileStatistics from '../pages/MobileStatisticsPage';
import MobileDimensionList from '../pages/MobileDimensionListPage';
import MobileCategoriesList from '../pages/MobileCategoriesListPage';
import MobileCategoryDetail from '../pages/MobileCategoryDetailPage';

import MobileTraining from '../pages/MobileTrainingPage';
import MobileEvaluation from '../pages/MobileEvaluationPage';

export const loginRouteTemplates = {
	loginPage: {
		path: '/',
		component: Login,
	},
	EmailConfirmationPage: {
		path: '/email-confirm',
		component: EmailConfirmation,
	},
};

export const widgetRouteTemplates = {
	dashboardPage: {
		path: '/',
		component: Dashboard,
	},
	trainingPage: {
		path: '/training',
		component: Training,
	},
	evaluationPage: {
		path: '/evaluation',
		component: Evaluation,
	},
	mobileStatisticsPage: {
		path: '/mobile-statistics',
		component: MobileStatistics,
	},
	mobileCategoriesListPage: {
		path: '/mobile-categories-list',
		component: MobileCategoriesList,
	},
	mobileDimensionListPage: {
		path: '/mobile-dimension-list',
		component: MobileDimensionList,
	},
	mobileCategoryDetailPage: {
		path: '/mobile-category-detail',
		component: MobileCategoryDetail,
	},
	mobileTrainingPage: {
		path: '/mobile-training',
		component: MobileTraining,
	},
	mobileEvaluationPage: {
		path: '/mobile-evaluation',
		component: MobileEvaluation,
	},
};
