import React from 'react';
import { Grid } from '@material-ui/core';
// import { useTheme } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import useStyles from './styles';

import { BoxCenter, Typography } from '../../components/Wrappers/Wrappers';

import Button from '@material-ui/core/Button';

import TypeformDialog from '../../components/Dialog/TypeformDialog';

import Widget from '../../components/Widget/Widget';

import { useHistory } from 'react-router-dom';

import { selectors as authSelectors } from '../../services/auth';

export default function Training(props) {
	var classes = useStyles();
	// var theme = useTheme();
	const history = useHistory();

	console.log('trainingPage');

	const selectedTab = useSelector(authSelectors.getSelectedTab);

	var category = props.location.state.category;

	const [OpenTypeformDialog, setOpenTypeformDialog] = React.useState(false);
	const handleClickOpenTypeformDialog = () => {
		setOpenTypeformDialog(true);
	};
	const handleClickCloseTypeformDialog = () => {
		setOpenTypeformDialog(false);
		history.push({
			pathname: '/',
			state: { category: category },
		});
	};

	return (
		<>
			<Grid container spacing={4} justify={'center'}>
				<Grid
					style={{ marginLeft: '0px', marginRight: '0px', paddingLeft: '0', paddingRight: '0' }}
					item
					xs={12}
				>
					<Widget
						// title="Training"
						upperTitle
						className={classes.card}
						bodyClass={classes.fullHeightBody}
						disableWidgetMenu
						style={{ minHeight: 400 }}
					>
						<Grid item xs={12} sm={12}>
							<BoxCenter>
								<Typography variant="h1" size="sm">
									Training
								</Typography>
							</BoxCenter>
						</Grid>
						<Grid item xs={12} sm={12}>
							<BoxCenter>
								<Typography variant={'h2'}>{category.label}</Typography>
							</BoxCenter>
						</Grid>

						<Grid item xs={12} sm={12}>
							<BoxCenter px={4}>
								<Typography variant={'body2'}>
									<span>Items discovered : </span>
									<span>{category.number_of_questions_seen}</span>
								</Typography>
							</BoxCenter>
						</Grid>
						<Grid item xs={12} sm={12}>
							<BoxCenter px={4}>
								<Typography variant={'body2'}>
									<span>Items mastered : </span>
									<span>{category.number_of_questions_mastered}</span>
								</Typography>
							</BoxCenter>
						</Grid>
						<Grid item xs={12} sm={12}>
							<BoxCenter px={4}>
								<Typography variant={'body2'}>
									<span>Items to discover : </span>
									<span>
										{' '}
										{category.number_of_existing_questions - category.number_of_questions_seen}
									</span>
								</Typography>
							</BoxCenter>
						</Grid>

						<Grid item xs={12} sm={12}>
							<BoxCenter>
								<Button
									onClick={(event) => {
										handleClickOpenTypeformDialog(event);
									}}
									variant={'contained'}
									color={'primary'}
								>
									Start Training
								</Button>
							</BoxCenter>
						</Grid>
					</Widget>
				</Grid>
			</Grid>

			<TypeformDialog
				type="followerGenerator"
				open={OpenTypeformDialog}
				onClose={handleClickCloseTypeformDialog}
				data={{ category: category, sessionType: 'training', selectedTab: selectedTab }}
			/>
		</>
	);
}
