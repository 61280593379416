import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';

import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';
import { MobileMenu, MobileMenuMargin } from '../../components/MobileMenu';

import { useHistory } from 'react-router-dom';

import { selectors as authSelectors } from '../../services/auth';

import { refreshGraphData, refreshToken } from '../../services/callsUtils';

import { SubCategList } from './components/SubCategList';

import StarIcon from '@material-ui/icons/Star';
import School from '@material-ui/icons/School';

export default function MobileDimensionList() {
	var classes = useStyles();

	const history = useHistory();
	const dispatch = useDispatch();

	const tabsData = useSelector(authSelectors.getTabsData);
	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);
	const apiCallContext = useSelector(authSelectors.getApiCallContext);
	const tabsDataCollectTime = useSelector(authSelectors.getTabsDataCollectTime);
	const accessToken = useSelector(authSelectors.getAccessToken);

	useEffect(() => {
		refreshGraphData(
			internalConfiguration.radar_chart_id,
			apiCallContext,
			dispatch,
			tabsDataCollectTime
		);
		if (internalConfiguration.is_loggin_functionality_required) {
			refreshToken(dispatch, history, apiCallContext, accessToken, internalConfiguration);
		}
	}, []);

	function prepareDimension(dimension) {
		return (
			<>
				<Grid container xs={12} className={classes.categSubCategListContainer}>
					<Grid container xs={12} className={classes.categSubCategListWidget}>
						<Grid container xs={12} className={classes.categSubCategListWidgetTitle}>
							<Grid container xs={12}>
								<span style={{ color: dimension.color }}>{dimension.name}</span>

								<div className={classes.dimensionProgressIcon1Suround}>
									<StarIcon
										className={classes.dimensionProgressIcon1}
										style={{
											color: dimension.color,
										}}
									/>
								</div>
								<div className={classes.dimensionProgressValue}>{dimension.score.toFixed(0)}%</div>

								<div className={classes.dimensionProgressIcon1Suround}>
									<School
										className={classes.dimensionProgressIcon1}
										style={{
											color: dimension.color,
										}}
									/>
								</div>
								<div className={classes.dimensionProgressValue}>
									{dimension.mastered.toFixed(0)} %
								</div>
							</Grid>
						</Grid>
						<Grid item xs={12} className={classes.categSubCategList}>
							<SubCategList dimension={dimension} />
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}

	function categoriesList() {
		var listItems = [];

		for (var dimensionIndex in tabsData) {
			listItems.push(prepareDimension(tabsData[dimensionIndex]));
		}

		return <>{listItems}</>;
	}

	return (
		<>
			<Grid container justify={'center'} xs={12}>
				<MobileHeader goBack={true} pageTitle="Categories" />
				<MobileHeaderMargin />

				<Grid item xs={12} className={classes.mobileCategorySection2Title}>
					<span>Progression by category</span>
				</Grid>

				{categoriesList()}

				<Grid container xs={12} justify={'center'}>
					<MobileMenuMargin />
					<MobileMenu activePage="categories" />
				</Grid>
			</Grid>
		</>
	);
}
