import React from 'react';
import Box from '@material-ui/core/Box';
import { LinearProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 18,
		borderRadius: theme.globalBorderRadius,
	},
	colorPrimary: {
		backgroundColor: theme.palette.text.hint,
	},
	bar: {
		borderRadius: theme.globalBorderRadius,
		backgroundColor: (props) => {
			return props.barColor || theme.palette.primary.main;
		},
	},
}))(LinearProgress);

const Linear = ({ value, barColor }) => {
	return (
		<Box style={{ position: 'relative' }}>
			<BorderLinearProgress variant="determinate" barColor={barColor} value={Math.round(value)} />
			<Box position={'absolute'} left={'50%'} top={'0'}>
				{Math.round(value)}%
			</Box>
		</Box>
	);
};

export default Linear;
