import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { fetchWrapper } from '../../services/fetchWrapper';

// import useStyles from './styles';

import { selectors as authSelectors } from '../../services/auth';

import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

export default function TypeForm(props) {
	// var classes = useStyles();

	var isEvaluation = props.data.isEvaluation;
	var category = props.data.category;

	var category_slug = null;
	if (category) {
		category_slug = category.slug;
	}

	const apiCallContext = useSelector(authSelectors.getApiCallContext);

	const init = async () => {
		startTypeForm(isEvaluation, category_slug);
	};

	useEffect(() => {
		init();
	}, []);

	const startTypeForm = async (is_evaluation, category_slug) => {
		const { form_id, typeform_user_identifier } = await fetchWrapper.post(
			'REACT_APP_FORM_API_URL',
			{ is_evaluation: is_evaluation, is_elearning: true, categories_slug: category_slug },
			apiCallContext
		);

		createWidget(form_id, {
			container: document.querySelector('#form'), // you need an element with 'form' id
			hideHeaders: true,
			hideFooter: true,
			opacity: 0,
			hidden: {
				user_identifier: typeform_user_identifier,
			},
		});
	};

	return (
		<>
			<div
				data-tf-widget="<form-id>"
				data-tf-hide-headers
				data-tf-hide-footer
				data-tf-opacity="0"
				id="form"
				style={{ height: '750px', width: '100%' }}
			></div>
		</>
	);
}
