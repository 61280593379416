import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { widgetRouteTemplates } from '../routeTemplates';

import useStyles from './styles';

function WidgetRouting() {
	var classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.fakeToolbar} />
			<Switch>
				<Route
					exact
					path={widgetRouteTemplates.dashboardPage.path}
					component={widgetRouteTemplates.dashboardPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.trainingPage.path}
					component={widgetRouteTemplates.trainingPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.evaluationPage.path}
					component={widgetRouteTemplates.evaluationPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.mobileStatisticsPage.path}
					component={widgetRouteTemplates.mobileStatisticsPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.mobileCategoriesListPage.path}
					component={widgetRouteTemplates.mobileCategoriesListPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.mobileDimensionListPage.path}
					component={widgetRouteTemplates.mobileDimensionListPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.mobileCategoryDetailPage.path}
					component={widgetRouteTemplates.mobileCategoryDetailPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.mobileTrainingPage.path}
					component={widgetRouteTemplates.mobileTrainingPage.component}
				/>
				<Route
					exact
					path={widgetRouteTemplates.mobileEvaluationPage.path}
					component={widgetRouteTemplates.mobileEvaluationPage.component}
				/>
			</Switch>
		</div>
	);
}

export default WidgetRouting;
