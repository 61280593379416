import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import { RootState } from '../../store/reducer';

// export const getModuleState = (state) => state.auth;
export const getModuleState = (state: RootState) => state.auth;

export const selectors = {
	isAuthenticated(state: RootState) {
		const { isAuthenticated } = getModuleState(state);
		return isAuthenticated;
	},
	getAccessToken(state: RootState) {
		const { accessToken } = getModuleState(state);
		return accessToken;
	},
	getApiCallContext(state: RootState) {
		const { externalConfiguration, internalConfiguration, accessToken } = getModuleState(state);
		var context = {};
		if (internalConfiguration.is_loggin_functionality_required) {
			context = {
				token: accessToken.token,
				tenant_slug: externalConfiguration.tenant_slug,
				isLoggedIn: true,
			};
		} else {
			context = {
				token: externalConfiguration.token,
				tenant_slug: externalConfiguration.tenant_slug,
				isLoggedIn: false,
			};
		}

		return context;
	},
	getExternalConfiguration: (state: RootState) => getModuleState(state).externalConfiguration,
	getInitialToken: (state: RootState) => getModuleState(state).externalConfiguration.initialToken,
	getExternalToken: (state: RootState) => getModuleState(state).externalConfiguration.token,
	getInternalConfiguration: (state: RootState) => getModuleState(state).internalConfiguration,
	getTabsData: (state: RootState) => getModuleState(state).tabsData,
	getSelectedTab: (state: RootState) => getModuleState(state).selectedTab,
	getTabsDataCollectTime: (state: RootState) => getModuleState(state).tabsDataCollectTime,
};

const slice = createSlice({
	name: 'auth',
	initialState: {
		isAuthenticated: false,
		accessToken: {
			token: undefined,
		},
		username: null,
		externalConfiguration: {
			widget_id: '',
			tenant_slug: '',
			token: '',
			initialToken: '',
		},
		internalConfiguration: {
			is_loggin_functionality_required: true,
			is_registration_functionality_enabled: true,
			is_activation_code_required: false,
			organization_id: '',
			radar_chart_id: '',
			title: '',
			widget_name: '',
			description: '',
		},
		tabsData: null,
		selectedTab: null,
		tabsDataCollectTime: new Date().getTime(),
	},
	reducers: {
		login(state, { payload }) {
			const { username, accessToken } = payload;
			state.isAuthenticated = true;
			state.accessToken = accessToken;
			state.username = username;
		},
		logout(state) {
			state.isAuthenticated = false;
			state.accessToken = { token: undefined };
			state.username = null;
		},
		setInitWidgetConfiguration(state, { payload }) {
			const { externalConfiguration, internalConfiguration } = payload;
			state.externalConfiguration = externalConfiguration;
			state.internalConfiguration = internalConfiguration;
		},
		setExternalConfiguration(state, { payload }) {
			const { widget_id, tenant_slug, token } = payload;

			state.externalConfiguration.widget_id = widget_id;
			state.externalConfiguration.tenant_slug = tenant_slug;
			state.externalConfiguration.token = token;
			state.externalConfiguration.initialToken = token;
		},
		setAccessToken(state, { payload }) {
			state.accessToken = payload;
		},
		setInternalConfiguration(state, { payload }) {
			const {
				is_loggin_functionality_required,
				is_registration_functionality_enabled,
				is_activation_code_required,
				organization_id,
				radar_chart_id,
				title,
				description,
			} = payload;

			state.internalConfiguration.is_loggin_functionality_required =
				is_loggin_functionality_required;
			state.internalConfiguration.is_registration_functionality_enabled =
				is_registration_functionality_enabled;
			state.internalConfiguration.is_activation_code_required =
				is_activation_code_required;
			state.internalConfiguration.organization_id = organization_id;
			state.internalConfiguration.radar_chart_id = radar_chart_id;
			state.internalConfiguration.title = title;
			state.internalConfiguration.description = description;
		},
		setTabsData(state, { payload }) {
			state.tabsData = payload.mappedData;
			state.tabsDataCollectTime = payload.currentTime;
		},

		setInitTabsData(state, { payload }) {
			state.tabsData = payload.mappedData;
			state.tabsDataCollectTime = payload.currentTime;
			state.selectedTab = payload.selectedTab;
		},

		setSelectedTab(state, { payload }) {
			state.selectedTab = payload;
		},
	},
});

export const actions = slice.actions;
export const reducer = persistReducer(
	{
		key: 'auth',
		storage: localStorage,
	},
	slice.reducer
);
