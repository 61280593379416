import tinycolor from 'tinycolor2';

export const primary = '#536DFE';
export const secondary = '#FF5C93';
export const tertiary = '#FF5C93';
export const score = '#ffc658';

export const defaultBG = '#F6F7FF';
export const defaultLBG = '#F3F5FF';
export const defaultLSecondaryBG = '#FFFF';

export const defaultTextPrimary = '#4A4A4A';
export const defaultTextSecondary = '#6E6E6E';
export const defaultOverPrimaryBackground = '#FFFF';
export const defaultOverSecondaryBackground = '#FFFF';

const warning = '#FFC260';
const success = '#3CD4A0';
const info = '#9013FE';

export const lightenRate = 7.5;
export const darkenRate = 15;

const defaultTheme = {
	palette: {
		primary: {
			main: primary,
			light: tinycolor(primary).lighten(lightenRate).toHexString(),
			dark: tinycolor(primary).darken(darkenRate).toHexString(),
		},
		secondary: {
			main: secondary,
			light: tinycolor(secondary).lighten(lightenRate).toHexString(),
			dark: tinycolor(secondary).darken(darkenRate).toHexString(),
			contrastText: '#FFFFFF',
		},
		tertiary: {
			main: tertiary,
			light: tinycolor(tertiary).lighten(lightenRate).toHexString(),
			dark: tinycolor(tertiary).darken(darkenRate).toHexString(),
		},
		score: {
			main: score,
			light: tinycolor(score).lighten(lightenRate).toHexString(),
			dark: tinycolor(score).darken(darkenRate).toHexString(),
		},
		warning: {
			main: warning,
			light: tinycolor(warning).lighten(lightenRate).toHexString(),
			dark: tinycolor(warning).darken(darkenRate).toHexString(),
		},
		success: {
			main: success,
			light: tinycolor(success).lighten(lightenRate).toHexString(),
			dark: tinycolor(success).darken(darkenRate).toHexString(),
		},
		info: {
			main: info,
			light: tinycolor(info).lighten(lightenRate).toHexString(),
			dark: tinycolor(info).darken(darkenRate).toHexString(),
		},
		text: {
			primary: defaultTextPrimary,
			secondary: defaultTextSecondary,
			overPrimaryBackground: defaultOverPrimaryBackground,
			overSecondaryBackground: defaultOverSecondaryBackground,
			hint: '#B9B9B9',
		},
		background: {
			default: defaultBG,
			light: defaultLBG,
			secondary: defaultLSecondaryBG,
		},
	},
	customShadows: {
		widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
		widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
		widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
	},
	globalBorderRadius: 40,
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: '#4A4A4A1A',
			},
		},
		MuiMenu: {
			paper: {
				boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
			},
		},
		MuiSelect: {
			icon: {
				color: '#B9B9B9',
			},
		},
		MuiListItem: {
			root: {
				'&$selected': {
					backgroundColor: '#F3F5FF !important',
					'&:focus': {
						backgroundColor: '#F3F5FF',
					},
				},
			},
			button: {
				'&:hover, &:focus': {
					backgroundColor: '#F3F5FF',
				},
			},
		},
		MuiTouchRipple: {
			child: {
				backgroundColor: 'white',
			},
		},
		MuiTableRow: {
			root: {
				height: 56,
			},
		},
		MuiTableCell: {
			root: {
				borderBottom: '1px solid rgba(224, 224, 224, .5)',
				paddingLeft: 24,
			},
			head: {
				fontSize: '0.95rem',
			},
			body: {
				fontSize: '0.95rem',
			},
		},
		PrivateSwitchBase: {
			root: {
				marginLeft: 10,
			},
		},
	},
};

export default defaultTheme;
