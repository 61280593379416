export function calculateCategScore(category) {
	if (category.score == null) {
		return 0;
	}
	var value = (category.score * 100).toFixed(0);
	return value;
}

export function calculateCategEvaluationReadiness(category) {
	if (
		category.occurence_since_last_evaluation == 0 ||
		category.occurence_since_last_evaluation == null
	) {
		return 0;
	}
	var value = (
		(category.occurence_since_last_evaluation / category.evaluation_trigger_occurence_threshold) *
		100
	).toFixed(0);
	return value;
}

export function calculateCategDiscorevy(category) {
	if (category.number_of_questions_seen == 0 || category.number_of_questions_seen == null) {
		return 0;
	}
	var value = (
		(category.number_of_questions_seen / category.number_of_existing_questions) *
		100
	).toFixed(0);
	return value;
}

export function calculateCategMastery(category) {
	if (category.number_of_questions_mastered == 0 || category.number_of_questions_mastered == null) {
		return 0;
	}
	var value = (
		(category.number_of_questions_mastered / category.number_of_existing_questions) *
		100
	).toFixed(0);
	return value;
}

export function processAdditionalDataOnGraphData(graphData) {
	for (var dimensionIndex in graphData) {
		var dimension = graphData[dimensionIndex];
		for (var categoryIndex in dimension.categories) {
			var category = dimension.categories[categoryIndex];
			graphData[dimensionIndex].categories[categoryIndex].score = calculateCategScore(category);
			graphData[dimensionIndex].categories[categoryIndex].evaluationReadiness =
				calculateCategEvaluationReadiness(category);
			graphData[dimensionIndex].categories[categoryIndex].discovery =
				calculateCategDiscorevy(category);
			graphData[dimensionIndex].categories[categoryIndex].mastered =
				calculateCategMastery(category);
		}
	}
	return graphData;
}
