import React from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Grid, IconButton } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Typography, BoxCenter } from '../../components/Wrappers/Wrappers';

import useStyles from './styles';

import { selectors as authSelectors } from '../../services/auth';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export function MobileHeaderMargin() {
	var classes = useStyles();

	return <span className={classes.mobileHeaderMargin}>{' d'}</span>;
}

export function MobileHeader(props) {
	// export default function MobileHeader(props) {
	var classes = useStyles();
	const history = useHistory();

	var goBack = props.goBack;
	var pageTitle = props.pageTitle;

	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);

	if (pageTitle == undefined || pageTitle == null) {
		pageTitle = internalConfiguration.title;
	}

	return (
		<>
			{/* <AppBar position="static" className={classes.mobileHeaderTitleContainerGrid}> */}
			<AppBar className={classes.mobileHeaderTitleContainerGrid}>
				<Toolbar>
					<Grid item xs={2} className={classes.mobileHeaderBackIconGrid}>
						{(() => {
							if (goBack)
								return (
									<BoxCenter>
										<IconButton
											color="inherit"
											aria-haspopup="true"
											aria-controls="mail-menu"
											onClick={() => history.goBack()}
											className={classes.mobileHeaderBackButton}
										>
											<ArrowBackIcon className={classes.mobileHeaderBackIcon} />
										</IconButton>
									</BoxCenter>
								);
						})()}
					</Grid>
					<Grid item xs={8} className={classes.mobileHeaderTitleGrid}>
						<BoxCenter>
							<Typography component="span" variant="h3" color="textPrimary">
								{pageTitle}
							</Typography>
						</BoxCenter>
					</Grid>

					<Grid item xs={2}></Grid>

					{/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{pageTitle}
					</Typography> */}
				</Toolbar>
			</AppBar>

			{/* <Grid container xs={12} className={classes.mobileHeaderTitleContainerGrid}>
				<Grid item xs={2} className={classes.mobileHeaderBackIconGrid}>
					{(() => {
						if (goBack)
							return (
								<BoxCenter>
									<IconButton
										color="inherit"
										aria-haspopup="true"
										aria-controls="mail-menu"
										onClick={() => history.goBack()}
										className={classes.mobileHeaderBackButton}
									>
										<ArrowBackIcon className={classes.mobileHeaderBackIcon} />
									</IconButton>
								</BoxCenter>
							);
					})()}
				</Grid>
				<Grid item xs={8} className={classes.mobileHeaderTitleGrid}>
					<BoxCenter>
						<Typography component="span" variant="h2" color="textPrimary">
							{pageTitle}
						</Typography>
					</BoxCenter>
				</Grid>

				<Grid item xs={2}></Grid>
			</Grid> */}
		</>
	);
}
