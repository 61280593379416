import React from 'react';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer, PolarAngleAxis } from 'recharts';

export const CustomRadialBarChart = ({ data, halfCircle }) => {
	if (halfCircle) {
		const style = {
			top: '55%',
			right: 0,
			transform: 'translate(0, -50%)',
			lineHeight: '24px',
		};
		return (
			<ResponsiveContainer width="100%" height="100%">
				<RadialBarChart
					cx="50%"
					cy="50%"
					innerRadius="70%"
					outerRadius="90%"
					startAngle={0}
					endAngle={-180}
					barSize={30}
					data={data}
					transform="rotate(180)"
				>
					<PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
					<RadialBar
						minAngle={15}
						label={{ position: 'insideStart', fill: '#fff' }}
						background
						dataKey="value"
					/>
					<Legend iconSize={10} layout="horizontal" verticalAlign="middle" wrapperStyle={style} />
				</RadialBarChart>
			</ResponsiveContainer>
		);
	} else {
		const style = {
			top: '95%',
			right: 0,
			transform: 'translate(0, -50%)',
			lineHeight: '24px',
		};
		return (
			<ResponsiveContainer width="100%" height="100%">
				<RadialBarChart
					cx="50%"
					cy="50%"
					innerRadius="50%"
					outerRadius="80%"
					startAngle={0}
					endAngle={-360}
					barSize={20}
					data={data}
					transform="rotate(230)"
				>
					<PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
					<RadialBar
						minAngle={15}
						label={{ position: 'insideStart', fill: '#fff' }}
						background
						dataKey="value"
					/>
					<Legend iconSize={10} layout="horizontal" verticalAlign="middle" wrapperStyle={style} />
				</RadialBarChart>
			</ResponsiveContainer>
		);
	}
};
