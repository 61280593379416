import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 
  @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url("/fonts/proxima_nova/ProximaNova_300.otf");
    }

    @font-face {
        font-family: "Proxima Nova"; 
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("/fonts/proxima_nova/ProximaNova_400.otf");
    }

    @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url("/fonts/proxima_nova/ProximaNova_600.otf");
    }

    @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url("/fonts/proxima_nova/ProximaNova_700.otf");
    }
`;
