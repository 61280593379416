import React, { useState } from 'react';
import {
	Grid,
	CircularProgress,
	Typography,
	Button,
	Tabs,
	Tab,
	TextField,
	Fade,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

// styles
import useStyles from './styles';

// logo
import logo from './logo.svg';
// import google from '../../images/google.svg';

import { actions as authActions, selectors as authSelectors } from '../../services/auth';

import { fetchWrapper } from '../../services/fetchWrapper';

function Login() {
	var classes = useStyles();

	const apiCallContext = useSelector(authSelectors.getApiCallContext);
	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);
	const externalConfiguration = useSelector(authSelectors.getExternalConfiguration);

	const dispatch = useDispatch();

	// local
	var [isLoading, setIsLoading] = useState(false);

	var [error, setError] = useState(null);
	var [errorMessage, setErrorMessage] = useState('');

	var [activeTabId, setActiveTabId] = useState(0);
	var [emailValue, setEmailValue] = useState('');
	var [passwordValue, setPasswordValue] = useState('');

	var [registrationFirstNameValue, setRegistrationFirstNameValue] = useState('');
	var [registrationLastNameValue, setRegistrationLastNameValue] = useState('');
	var [registrationEmailValue, setRegistrationEmailValue] = useState('');
	var [registrationPasswordValue, setRegistrationPasswordValue] = useState('');
	var [registrationActivationCodeValue, setRegistrationActivationCodeValue] = useState(undefined);

	const loginUser = async (email, password, setIsLoading, setError) => {
		setError(false);
		setIsLoading(true);

		if (!!email && !!password) {
			const response = await fetchWrapper.post(
				'LOGIN_API_URL',
				{ email: email, password: password },
				apiCallContext
			);

			if (response) {
				setError(null);
				setIsLoading(false);
				return response;
			}
			setIsLoading(false);

			return response;
		} else {
			setError(true);
			setIsLoading(false);
		}

		return false;
	};

	function onSubmitLogin() {
		loginUser(emailValue, passwordValue, setIsLoading, setError)
			.then((response) => {
				setError(null);
				dispatch(
					authActions.login({ username: emailValue, accessToken: { token: response.token } })
				);
			})
			.catch(function (e) {
				console.log(e);
				setErrorMessage(e.non_field_errors[0]);
				setError(true);
				setIsLoading(false);
			});
	}

	const registerUser = async (
		email,
		password,
		activationCode,
		firstName,
		lastName,
		setIsLoading,
		setError
	) => {
		setError(false);
		setIsLoading(true);

		// When activation code required, call is made on invitation confirmation API
		// when not activation code required, call made on authentication API

		if (!!email && !!password) {
			if (internalConfiguration.is_activation_code_required) {
				const response = await fetchWrapper.post(
					'INVITATION_CONFIRM_API_URL',
					{
						email: email,
						password1: password,
						activation_code: activationCode,
						first_name: firstName,
						last_name: lastName,
						cgu: true,
						cgv: true,
						password2: password,
						privacy: true,
					},
					apiCallContext
				);
				setError(null);
				setIsLoading(false);
				return response;
			} else {
				const response = await fetchWrapper.post(
					'REGISTER_API_URL',
					{
						email: email,
						password1: password,
						first_name: firstName,
						last_name: lastName,
						widget_id: externalConfiguration.widget_id,
					},
					apiCallContext
				);
				setError(null);
				setIsLoading(false);
				return response;
			}
		} else {
			setError(true);
			setIsLoading(false);
		}

		return false;
	};

	function onSubmitRegister() {
		registerUser(
			registrationEmailValue,
			registrationPasswordValue,
			registrationActivationCodeValue,
			registrationFirstNameValue,
			registrationLastNameValue,
			setIsLoading,
			setError
		)
			.then((response) => {
				setError(null);
				dispatch(
					authActions.login({
						username: registrationEmailValue,
						accessToken: { token: response.token },
					})
				);
			})
			.catch(function (e) {
				setError(true);
				setIsLoading(false);
				console.error(e.message);
			});
	}

	return (
		<Grid container className={classes.container}>
			<div className={classes.logotypeContainer}>
				<img src={logo} alt="logo" className={classes.logotypeImage} />
				<Typography className={classes.logotypeText}>{internalConfiguration.title}</Typography>
			</div>

			<div className={classes.logotypeMiniContainer}>
				<img src={logo} alt="logo" className={classes.logotypeImage} />
				<Typography className={classes.logotypeText}>{internalConfiguration.title}</Typography>
			</div>

			<div className={classes.formContainer}>
				<div className={classes.form}>
					{(() => {
						if (internalConfiguration.is_registration_functionality_enabled) {
							return (
								<Tabs
									value={activeTabId}
									onChange={(e, id) => setActiveTabId(id)}
									indicatorColor="primary"
									textColor="primary"
									centered
								>
									<Tab label="Login" classes={{ root: classes.tab }} />
									<Tab label="New User" classes={{ root: classes.tab }} />
								</Tabs>
							);
						}
					})()}

					{activeTabId === 0 && (
						<React.Fragment>
							<Typography variant="h1" className={classes.greeting}>
								Enjoy!
							</Typography>
							<Typography variant="h2" className={classes.subGreeting}>
								Login to your account
							</Typography>

							{/* <Button size="large" className={classes.googleButton}>
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div> */}

							<Fade in={error}>
								{errorMessage != '' ? (
									<Typography color="secondary" className={classes.errorMessage}>
										{errorMessage}
									</Typography>
								) : (
									<Typography color="secondary" className={classes.errorMessage}>
										Something is wrong with your login or password :(
									</Typography>
								)}
							</Fade>
							<TextField
								id="email"
								InputProps={{
									classes: {
										underline: classes.textFieldUnderline,
										input: classes.textField,
									},
								}}
								value={emailValue}
								onChange={(e) => setEmailValue(e.target.value)}
								margin="normal"
								placeholder="Email Adress"
								type="email"
								fullWidth
							/>
							<TextField
								id="password"
								InputProps={{
									classes: {
										underline: classes.textFieldUnderline,
										input: classes.textField,
									},
								}}
								value={passwordValue}
								onChange={(e) => setPasswordValue(e.target.value)}
								margin="normal"
								placeholder="Password"
								type="password"
								fullWidth
							/>
							<div className={classes.formButtons}>
								{isLoading ? (
									<CircularProgress size={26} className={classes.loginLoader} />
								) : (
									<Button
										disabled={emailValue.length === 0 || passwordValue.length === 0}
										onClick={() => onSubmitLogin()}
										variant="contained"
										color="primary"
										size="large"
									>
										Login
									</Button>
								)}

								<Button color="primary" size="large" className={classes.forgetButton}>
									Forgotten Password
								</Button>
							</div>
						</React.Fragment>
					)}
					{activeTabId === 1 && (
						<React.Fragment>
							<Typography variant="h1" className={classes.greeting}>
								Welcome!
							</Typography>
							<Typography variant="h2" className={classes.subGreeting}>
								Create your account
							</Typography>
							<Fade in={error}>
								<Typography color="secondary" className={classes.errorMessage}>
									Something is wrong with your login or password :(
								</Typography>
							</Fade>
							<TextField
								id="firstName"
								InputProps={{
									classes: {
										underline: classes.textFieldUnderline,
										input: classes.textField,
									},
								}}
								value={registrationFirstNameValue}
								onChange={(e) => setRegistrationFirstNameValue(e.target.value)}
								margin="normal"
								placeholder="First Name"
								type="email"
								fullWidth
							/>
							<TextField
								id="lastName"
								InputProps={{
									classes: {
										underline: classes.textFieldUnderline,
										input: classes.textField,
									},
								}}
								value={registrationLastNameValue}
								onChange={(e) => setRegistrationLastNameValue(e.target.value)}
								margin="normal"
								placeholder="Last Name"
								type="email"
								fullWidth
							/>
							<TextField
								id="email"
								InputProps={{
									classes: {
										underline: classes.textFieldUnderline,
										input: classes.textField,
									},
								}}
								value={registrationEmailValue}
								onChange={(e) => setRegistrationEmailValue(e.target.value)}
								margin="normal"
								placeholder="Email Adress"
								type="email"
								fullWidth
							/>
							<TextField
								id="password"
								InputProps={{
									classes: {
										underline: classes.textFieldUnderline,
										input: classes.textField,
									},
								}}
								value={registrationPasswordValue}
								onChange={(e) => setRegistrationPasswordValue(e.target.value)}
								margin="normal"
								placeholder="Password"
								type="password"
								fullWidth
							/>

							{(() => {
								if (internalConfiguration.is_activation_code_required) {
									return (
										<TextField
											id="activationCode"
											InputProps={{
												classes: {
													underline: classes.textFieldUnderline,
													input: classes.textField,
												},
											}}
											value={registrationActivationCodeValue}
											onChange={(e) => setRegistrationActivationCodeValue(e.target.value)}
											margin="normal"
											placeholder="activation code"
											type=""
											fullWidth
										/>
									);
								}
							})()}

							<div className={classes.creatingButtonContainer}>
								{isLoading ? (
									<CircularProgress size={26} />
								) : (
									<Button
										onClick={() => onSubmitRegister()}
										disabled={
											registrationEmailValue.length === 0 ||
											registrationPasswordValue.length === 0 ||
											registrationFirstNameValue.length === 0 ||
											registrationLastNameValue.length === 0
										}
										size="large"
										variant="contained"
										color="primary"
										fullWidth
										className={classes.createAccountButton}
									>
										Create your account
									</Button>
								)}
							</div>

							{/* <div className={classes.formDividerContainer}>
								<div className={classes.formDivider} />
								<Typography className={classes.formDividerWord}>or</Typography>
								<div className={classes.formDivider} />
							</div>
							<Button
								size="large"
								className={classnames(classes.googleButton, classes.googleButtonCreating)}
							>
								<img src={google} alt="google" className={classes.googleIcon} />
								&nbsp;Sign in with Google
							</Button> */}
						</React.Fragment>
					)}
				</div>
			</div>
		</Grid>
	);
}

export default withRouter(Login);
