import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	categoryListElement: {
		borderRadius: theme.globalBorderRadius,
		height: '50px',
	},
	mobileCategorySection1Title: {
		fontSize: '20px',
		textAlign: 'center',
	},
	mobileCategorySection2Title: {
		marginBottom: '10px',
		marginLeft: '15px',
		fontSize: '20px',
		textAlign: 'left',
	},

	categSubCategListContainer: {
		width: '100%',
		marginBottom: '20px',
	},
	categSubCategListWidget: {
		borderRadius: theme.globalBorderRadius,
		backgroundColor: theme.palette.background.light,
		// height: '375px',
		marginRight: '10px',
		marginLeft: '10px',
		overflow: 'hidden',
		boxShadow: theme.customShadows.widgetDark,
	},
	categSubCategListWidgetTitle: {
		fontSize: '18px',
		fontWeight: '450',
		marginLeft: '25px',
		marginTop: '5px',
	},
	categSubCategList: {
		width: '100%',
	},
	dimensionProgressIcon1Suround: {
		marginLeft: '20px',
		marginRight: '5px',
		borderWidth: '2px',
		borderRadius: '100%',
		// borderStyle: 'solid',
		borderColor: 'black',
		width: '27px',
		height: '27px',
		opacity: '0.8',
		boxShadow: theme.customShadows.widgetDark,
	},
	dimensionProgressIcon1: {},
	progressIcon2: {
		fontSize: '22px',
		marginBottom: '5px',
	},

	progressValue: {
		fontWeight: '700',
	},
}));
