export function getUrlEndpoint(endpointName) {
	const urlPrefix = process.env.REACT_APP_PREFIX_URL;

	const urlsEndPointDictionary = {
		REACT_APP_GRAPH_API_URL: {
			logedIn: {
				url: urlPrefix + '/user/api/v1/e-learning/radar-chart/',
				tokenType: 'auth',
			},
			integrated: {
				url: urlPrefix + '/user/api/v1/e-learning/radar-chart/with-user-token/',
				tokenType: 'organizationUser',
			},
		},
		REACT_APP_FORM_API_URL: {
			logedIn: {
				url: urlPrefix + '/user/api/v1/e-learning/survey/new-session/',
				tokenType: 'auth',
			},
			integrated: {
				url: urlPrefix + '/user/api/v1/e-learning/survey/with-user-token/new-session/',
				tokenType: 'organizationUser',
			},
		},
		REACT_APP_WIDGET_CONF_URL: {
			url: urlPrefix + '/user/api/v1/widget-configuration/redar-chart/',
		},
		REACT_APP_TOKEN_REFRESH: {
			url: urlPrefix + '/api/v1/auth/token-refresh/',
		},
		LOGIN_API_URL: {
			url: urlPrefix + '/api/v1/auth/login/',
		},
		REGISTER_API_URL: {
			url: urlPrefix + '/api/v1/auth/register/',
		},
		INVITATION_CONFIRM_API_URL: {
			url: urlPrefix + '/api/v1/invitations/confirm/',
		},
		EMAIL_CONFIRM_API_URL: {
			url: urlPrefix + '/api/v1/auth/register/verify-email/',
		},
	};

	return urlsEndPointDictionary[endpointName];
}

export function getEndPointTokenType(urlDictionaryElement, loggedIn) {
	if (urlDictionaryElement.url) {
		return null;
	} else if (loggedIn) {
		return urlDictionaryElement.logedIn.tokenType;
	} else {
		return urlDictionaryElement.integrated.tokenType;
	}
}
