import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	mobileSection1: {
		paddingBottom: '20px',
		background:
			'linear-gradient(to left top, ' +
			theme.palette.primary.light +
			' , ' +
			theme.palette.secondary.main +
			')',
	},
	mobileSection1Title: {
		fontSize: '20px',
		textAlign: 'center',
		color: theme.palette.background.light,
	},
	mobileSection1SubTitle: {
		marginTop: '5px',
		fontSize: '15px',
		textAlign: 'center',
		color: theme.palette.background.light,
	},
	halfCircle: {
		marginBottom: '-60px',
		marginTop: '-20px',
	},

	mobileStartSession: {
		// marginBottom: '60px',
		bottom: '90px',
		position: 'fixed',
		width: '100%',
	},
	mobileStartSessionButton: {
		width: '90%',
		height: '50px',
		borderRadius: theme.globalBorderRadius,
		backgroundColor: theme.palette.background.light,
		fontSize: '18px',
	},
	mobileSection2: {
		paddingTop: '30px',
		background:
			'linear-gradient(to left top, ' +
			theme.palette.secondary.main +
			' , ' +
			theme.palette.primary.light +
			')',
	},
	mobileSection2Title: {
		fontSize: '20px',
		textAlign: 'left',
		marginLeft: '40px',
		color: theme.palette.background.light,
	},
	mobileSection2SubTitle: {
		marginTop: '5px',
		marginBottom: '25px',
		fontSize: '15px',
		textAlign: 'left',
		marginLeft: '40px',
		color: theme.palette.background.light,
	},
	mobileDetailListBorderMargin: {
		marginLeft: '15px',
		marginRight: '15px',
	},
	mobileCategoryDetailListElementHowTo: {
		marginTop: '-30px',
		marginBottom: '20px',
		paddingLeft: '20px',
		paddingRight: '15px',
		paddingTop: '3px',
		paddingBottom: '2px',
		backgroundColor: theme.palette.text.overPrimaryBackground,
		borderRadius: theme.globalBorderRadius,
		height: '40px',
		boxShadow: theme.customShadows.widgetDark,
		color: theme.palette.primary.main,
	},
	mobileCategoryDetailListElementDiscovered: {
		marginBottom: '20px',
		paddingLeft: '20px',
		paddingRight: '15px',
		paddingTop: '3px',
		paddingBottom: '2px',
		backgroundColor: theme.palette.text.overPrimaryBackground,
		borderRadius: theme.globalBorderRadius,
		height: '40px',
		boxShadow: theme.customShadows.widgetDark,
		color: theme.palette.primary.main,
	},
	mobileCategoryDetailListElementMastered: {
		marginBottom: '20px',
		paddingLeft: '20px',
		paddingRight: '15px',
		paddingTop: '3px',
		paddingBottom: '2px',
		backgroundColor: theme.palette.text.overPrimaryBackground,
		borderRadius: theme.globalBorderRadius,
		height: '40px',
		boxShadow: theme.customShadows.widgetDark,
		color: theme.palette.primary.main,
	},
	mobileCategoryDetailListElementScore: {
		marginBottom: '20px',
		paddingLeft: '20px',
		paddingRight: '15px',
		paddingTop: '3px',
		paddingBottom: '2px',
		backgroundColor: theme.palette.text.overPrimaryBackground,
		borderRadius: theme.globalBorderRadius,
		height: '40px',
		boxShadow: theme.customShadows.widgetDark,
		color: theme.palette.primary.main,
	},
	mobileHowToContainer: {
		marginTop: '3px',
	},
	mobileDiscoverContainer: {
		marginTop: '3px',
	},
	mobileDiscoverTitle: {
		fontSize: '15px',
		marginLeft: '35px',
	},
	mobileDiscoverProgress: {
		marginLeft: '25px',
		marginTop: '10px',
		fontSize: '17px',
		color: theme.palette.primary.main,

		// color: theme.palette.text.overPrimaryBackground,
	},
	mobileCategoryDetailListElementIconItem: {},
	mobileCategoryDetailListElementIcon: {
		marginTop: '-3px',
		marginLeft: '20px',
		fontSize: '25px',
		color: theme.palette.primary.main,
	},
	mobileCategoryDetailListElementIconHowTo: {
		marginTop: '-3px',
		marginLeft: '20px',
		fontSize: '25px',
		color: theme.palette.primary.main,
	},
	mobileSectionGraph: {
		backgroundColor: theme.palette.background.light,
		marginTop: '10px',
		marginBottom: '100px',
		marginLeft: '15px',
		marginRight: '15px',
		borderRadius: theme.globalBorderRadius,
		boxShadow: theme.customShadows.widgetDark,
	},
	mobileSectionGraphTitle: {
		fontSize: '18px',
		textAlign: 'left',
		marginLeft: '40px',
		marginTop: '10px',
		color: theme.palette.primary.main,
	},
	mobileSectionGraphBody: {
		marginBottom: '-20px',
	},
}));
