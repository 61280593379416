import React from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
} from 'recharts';

const CustomLineChart = ({ data, name1, name2 }) => {
	return (
		<>
			<ResponsiveContainer width="100%" height="100%" aspect={2}>
				<LineChart
					width={500}
					height={250}
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 10,
						bottom: 5,
					}}
				>
					<XAxis dataKey="name" />
					<YAxis />
					{/* <Tooltip /> */}
					<Legend />
					<ReferenceLine y={9800} label="Objectif" stroke="#ffc658" />

					<Line
						name={name1}
						type="monotone"
						dataKey="line1"
						stroke="#82ca9d"
						// activeDot={{ r: 4 }}
						dot={{ r: 3, strokeWidth: 2 }}
						// dot={<CustomizedDot />}
						strokeWidth={2}
						connectNulls
					/>

					<Line name={name2} type="monotone" dataKey="line2" stroke="#8884d8" />
				</LineChart>
			</ResponsiveContainer>
		</>
	);
};

export default CustomLineChart;
