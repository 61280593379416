import { makeApiUrl } from './makeApiUrl';
import { getUrlEndpoint, getEndPointTokenType } from './apiUrlsEndPoint';

export const fetchWrapper = {
	get,
	post,
	put,
	delete: _delete,
};

function finalPreparationHeaderBodyUrl(apiCallContext, apiEndPointName, callType, url, body) {
	const tokenType = getEndPointTokenType(
		getUrlEndpoint(apiEndPointName),
		apiCallContext.isLoggedIn
	);

	const headers = {
		'Content-Type': 'application/json',
	};

	if (tokenType == 'auth') {
		headers.Authorization = 'token ' + apiCallContext.token;
	}

	return { headers, body, url };
}

function get(apiEndPointName, apiCallContext, parameters) {
	const tempUrl = makeApiUrl(apiCallContext, apiEndPointName, parameters);
	const { headers, url } = finalPreparationHeaderBodyUrl(
		apiCallContext,
		apiEndPointName,
		'get',
		tempUrl
	);

	const requestOptions = {
		method: 'GET',
		headers: headers,
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function post(apiEndPointName, tempBody, apiCallContext, parameters) {
	const tempUrl = makeApiUrl(apiCallContext, apiEndPointName, parameters);
	const { headers, body, url } = finalPreparationHeaderBodyUrl(
		apiCallContext,
		apiEndPointName,
		'post',
		tempUrl,
		tempBody
	);

	const requestOptions = {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(body),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function put(apiEndPointName, tempBody, apiCallContext, parameters) {
	const tempUrl = makeApiUrl(apiCallContext, apiEndPointName, parameters);
	const { headers, body, url } = finalPreparationHeaderBodyUrl(
		apiCallContext,
		apiEndPointName,
		'put',
		tempUrl,
		tempBody
	);

	const requestOptions = {
		method: 'PUT',
		headers: headers,
		body: JSON.stringify(body),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(apiEndPointName, apiCallContext, parameters) {
	const tempUrl = makeApiUrl(apiCallContext, apiEndPointName, parameters);
	const { headers, url } = finalPreparationHeaderBodyUrl(
		apiCallContext,
		apiEndPointName,
		'delete',
		tempUrl
	);

	const requestOptions = {
		method: 'DELETE',
		headers: headers,
	};
	return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			//const error = (data && data.message) || response.statusText;
			return Promise.reject(data);
		}
		return data;
	});
}
