import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	categoryListElement: {
		borderRadius: theme.globalBorderRadius,
		height: '50px',
	},
	mobileCategoryList: {
		margin: '15px',
	},
	mobileCategorySection1Title: {
		fontSize: '20px',
		textAlign: 'center',
		color: theme.palette.text.primary,
	},
	mobileSection1SubTitle: {
		marginTop: '5px',
		fontSize: '15px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	mobileCategorySection2Title: {
		marginTop: '40px',
		marginBottom: '10px',
		marginLeft: '40px',
		fontSize: '20px',
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	mobileCategoryListDimension: {
		marginBottom: '20px',
		paddingLeft: '20px',
		paddingRight: '15px',
		paddingTop: '3px',
		paddingBottom: '2px',
		backgroundColor: theme.palette.background.light,
		borderRadius: theme.globalBorderRadius,
		height: '100px',
		boxShadow: theme.customShadows.widgetDark,
	},

	mobileCategoryListDimensionProgressContainer: {
		margin: '3px',
	},
	mobileCategoryListDimensionProgressContainerTitle: {
		color: theme.palette.text.primary,
	},
	mobileCategoryListDimensionTitlePart1: {
		color: theme.palette.primary.main,
		fontSize: '20px',
	},

	mobileCategoryListDimensionProgress: {
		color: theme.palette.text.secondary,
		width: '100%',
	},

	mobileCategoryListDimensionLinearProgress: {
		width: '50%',
		border: theme.globalBorderRadius,
	},

	mobileCategoryListDimensionIconItem: {
		marginRight: '-10px',
	},
	mobileCategoryListDimensionIcon: {
		marginTop: '28px',
		marginLeft: '20px',
		fontSize: '30px',
	},
}));
