import { getUrlEndpoint, getEndPointTokenType } from './apiUrlsEndPoint';

export function makeApiUrl(apiCallContext, apiEndPointName, parameters) {
	let endPoint = getUrlEndPoint(apiEndPointName, apiCallContext.isLoggedIn);
	const tokenType = getEndPointTokenType(
		getUrlEndpoint(apiEndPointName),
		apiCallContext.isLoggedIn
	);
	if (parameters) {
		endPoint = endPoint + parameters['1'] + '/';
	}
	if (tokenType == 'organizationUser') {
		endPoint = endPoint + apiCallContext.token;
	}
	return 'https://' + apiCallContext.tenant_slug + '.' + endPoint;
	// return 'http://' + apiCallContext.tenant_slug + '.' + endPoint;
}

function getUrlEndPoint(apiEndPointName, loggedIn) {
	return loggedInUrlOrIntegratedUrl(getUrlEndpoint(apiEndPointName), loggedIn);
}

function loggedInUrlOrIntegratedUrl(urlDictionaryElement, loggedIn) {
	if (urlDictionaryElement.url) {
		return urlDictionaryElement.url;
	} else if (loggedIn) {
		return urlDictionaryElement.logedIn.url;
	} else {
		return urlDictionaryElement.integrated.url;
	}
}
