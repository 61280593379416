import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ReactDOM from 'react-dom';
import App from './App';
import { debugContextDevtool } from 'react-context-devtool';

import store, { persistor } from './store/index';

const container = document.getElementById('root');
ReactDOM.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</ReduxProvider>
	</React.StrictMode>,
	container
);

debugContextDevtool(container, {
	disable: process.env.NODE_ENV === 'production' || !window._REACT_CONTEXT_DEVTOOL,
	debugReducer: false,
});
