import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

import TypeForm from '../../TypeForm';

export default function TypeformDialog(props) {
	var category = props.data.category;
	var sessionType = props.data.sessionType;

	var isEvaluation = null;
	if (sessionType == 'training') {
		isEvaluation = false;
	} else {
		isEvaluation = true;
	}

	const onSave = () => {
		props.onClose();
	};

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	return (
		<>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				fullScreen
				TransitionComponent={Transition}
				// PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: 'move' }}>Training {category.label}</DialogTitle>
				<DialogContent>
					<Grid xs={12} sm={12}>
						<TypeForm data={{ isEvaluation: isEvaluation, category: category }} />
					</Grid>

					<DialogActions>
						<Button onClick={props.onClose} color="primary">
							Annuler
						</Button>
						<Button onClick={onSave} color="primary">
							Valider
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
}
