import React from 'react';
import useStyles from './styles';

import { Grid } from '@material-ui/core';
// import { useTheme } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';
import { MobileMenu, MobileMenuMargin } from '../../components/MobileMenu';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import BarChart from '@material-ui/icons/BarChart';

import Button from '@material-ui/core/Button';

import { useDispatch } from 'react-redux';

import { doLogout } from '../../services/callsUtils';

import brain from '../../images/brain-2730372_640.png';

import evaluationImg from '../../images/owl-4760851_640.png';

const DashboardMobileVersion = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();

	function handleSessionButtonClick() {
		history.push({
			pathname: '/mobile-training',
		});
	}

	function handleMenuButtonClick(page) {
		if (page == 'training') {
			history.push({
				pathname: '/mobile-training',
			});
		} else if (page == 'trainingList') {
			history.push({
				pathname: '/mobile-dimension-list',
			});
		} else if (page == 'evaluationList') {
			history.push({
				pathname: '/mobile-evaluation-list',
			});
		} else if (page == 'statistics') {
			history.push({
				pathname: '/mobile-statistics',
			});
		} else if (page == 'logout') {
			doLogout(dispatch, history);
		}
	}

	return (
		<>
			<Grid container xs={12} justify={'center'}>
				<MobileHeader />
				<MobileHeaderMargin />
			</Grid>

			<Grid container xs={12} className={classes.mobileHomeIAContainer}>
				<Grid container xs={12} className={classes.mobileHomeIAItem}>
					<Grid container xs={12} className={classes.mobileHomeIAItem1}>
						<Grid item xs={3} className={classes.mobileHomeIAItem1Left}></Grid>
						<Grid item xs={9} className={classes.mobileHomeIAItem1Right}>
							<img src={brain} alt="img" className={classes.mobileHomeIAImage} />
						</Grid>
					</Grid>

					<Grid container xs={12} className={classes.mobileHomeIAItem2}>
						<Grid item xs={8}>
							<span className={classes.mobileHomeAIItem2Wording}>AI guided training</span>
						</Grid>
						<Grid item xs={4}>
							<Button
								onClick={() => handleSessionButtonClick()}
								variant={'contained'}
								color={'primary'}
								className={classes.mobileHomeAIItem2CTA}
							>
								CONTINUE
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container xs={12} className={classes.mobileHomeCategOfDayContainer}>
				<Grid container xs={12} className={classes.mobileHomeCategOfDayItem}>
					<Grid container xs={12} className={classes.mobileHomeCategOfDayItem1}>
						<Grid item xs={3} className={classes.mobileHomeCategOfDayItem1Left}>
							Category of the day
						</Grid>
						<Grid item xs={9} className={classes.mobileHomeCategOfDayItem1Right}>
							<img src={brain} alt="img" className={classes.mobileHomeCategOfDayImage} />
						</Grid>
					</Grid>

					<Grid container xs={12} className={classes.mobileHomeCategOfDayItem2}>
						<Grid item xs={8}>
							<Grid item xs={12}>
								<span className={classes.mobileHomeCategOfDayItem2Title}>Category name</span>
							</Grid>

							<Grid item xs={12}>
								<span className={classes.mobileHomeCategOfDayItem2Wording}>
									Always getting better
								</span>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Button
								onClick={() => handleSessionButtonClick()}
								variant={'contained'}
								color={'primary'}
								className={classes.mobileHomeCategOfDayItem2CTA}
							>
								START SESSION
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container xs={12} className={classes.mobileHomeEvalContainer}>
				<Grid container xs={12} className={classes.mobileHomeEvalItem}>
					<Grid item xs={4} className={classes.mobileHomeEvalLeft}>
						<img src={evaluationImg} alt="img" className={classes.mobileHomeEvalmage} />
					</Grid>

					<Grid item xs={8} className={classes.mobileHomeEvalRight}>
						<Grid container xs={12}>
							<span className={classes.mobileHomeEvalItemTitle}>You have X evaluations ready</span>
						</Grid>
						<Grid container xs={12}>
							<span className={classes.mobileHomeEvalItemWording}>
								We are using the scores to guide you on your trainings
							</span>
						</Grid>
						<Grid container xs={12}>
							<span className={classes.mobileHomeEvalItemCTA}>COMPLETE YOUR EVALUATIONS</span>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container xs={12} className={classes.endPageMargin}>
				<Grid item xs={1}>
					{' '}
				</Grid>
				<Grid item xs={10} className={classes.mobileMenu}>
					<ListItem
						alignItems="flex-start"
						button
						className={classes.mobileMenuButton}
						onClick={() => handleMenuButtonClick('logout')}
					>
						<ListItemIcon edge="start" className={classes.mobileMenuButtonIconItem}>
							<BarChart className={classes.mobileMenuButtonIcon} />
						</ListItemIcon>

						<ListItemText
							className={classes.mobileMenuButtonTitleContainer}
							primary={<span className={classes.mobileMenuButtonTitle}>Logout</span>}
						/>
					</ListItem>
				</Grid>
				<Grid item xs={1}>
					{' '}
				</Grid>
			</Grid>

			<Grid container xs={12} justify={'center'}>
				<MobileMenuMargin />
				<MobileMenu activePage="home" />
			</Grid>
		</>
	);
};

export default DashboardMobileVersion;
