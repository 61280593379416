import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import LoginRouting from './LoginRouting';
import WidgetRouting from './WidgetRouting';
import { useSelector } from 'react-redux';

import { selectors as authSelectors } from '../services/auth';

function calculateDisplayLogin() {
	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);
	const isAuthenticated = useSelector(authSelectors.isAuthenticated);
	const accessToken = useSelector(authSelectors.getAccessToken);

	if (internalConfiguration.is_loggin_functionality_required) {
		if (isAuthenticated && accessToken) {
			return false;
		}
		return true;
	}
	return false;
}

function Routing() {
	var displayLogin = calculateDisplayLogin();
	return (
		<Router>
			<CssBaseline />
			{displayLogin && <LoginRouting />}
			{!displayLogin && <WidgetRouting />}
		</Router>
	);
}

export { Routing };
