import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';
import { useTheme } from '@material-ui/core';

import { CustomRadialBarChart } from '../../components/Charts/CustomRadialBarChart';
import Linear from '../../components/Loader/LinearProgress';

import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';
import { MobileMenu, MobileMenuMargin } from '../../components/MobileMenu';

import { useHistory } from 'react-router-dom';

import { selectors as authSelectors } from '../../services/auth';

import { refreshGraphData, refreshToken } from '../../services/callsUtils';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import NavigateNext from '@material-ui/icons/NavigateNext';

export default function MobileCategoriesList(props) {
	var classes = useStyles();
	const theme = useTheme();

	const history = useHistory();
	const dispatch = useDispatch();

	var dimension = props.location.state.dimension;
	var categories = dimension.categories;

	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);
	const apiCallContext = useSelector(authSelectors.getApiCallContext);
	const tabsDataCollectTime = useSelector(authSelectors.getTabsDataCollectTime);
	const accessToken = useSelector(authSelectors.getAccessToken);

	useEffect(() => {
		refreshGraphData(
			internalConfiguration.radar_chart_id,
			apiCallContext,
			dispatch,
			tabsDataCollectTime
		);
		if (internalConfiguration.is_loggin_functionality_required) {
			refreshToken(dispatch, history, apiCallContext, accessToken, internalConfiguration);
		}
	}, []);

	function handleCategoryClick(category) {
		history.push({
			pathname: '/mobile-category-detail/',
			state: {
				category: category,
			},
		});
	}

	function prepareCategory(category) {
		return (
			<>
				<ListItem
					alignItems="flex-start"
					onClick={() => handleCategoryClick(category)}
					className={classes.mobileCategoryListDimension}
				>
					<ListItemAvatar>
						<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
					</ListItemAvatar>

					<ListItemText
						primary={
							<span className={classes.mobileCategoryListDimensionTitlePart1}>
								{category.label}
							</span>
						}
						secondary={
							<>
								<Grid container className={classes.mobileCategoryListDimensionProgressContainer}>
									<Grid
										item
										xs={5}
										className={classes.mobileCategoryListDimensionProgressContainerTitle}
									>
										Score
									</Grid>
									<Grid item xs={7}>
										<span className={classes.mobileCategoryListDimensionProgress}>
											<Linear value={category.score} barColor={theme.palette.score.dark} />
										</span>
									</Grid>
								</Grid>

								<Grid container className={classes.mobileCategoryListDimensionProgressContainer}>
									<Grid
										item
										xs={5}
										className={classes.mobileCategoryListDimensionProgressContainerTitle}
									>
										Mastered
									</Grid>
									<Grid item xs={7}>
										<span className={classes.mobileCategoryListDimensionProgress}>
											<Linear value={category.mastered} barColor={theme.palette.primary.light} />
										</span>
									</Grid>
								</Grid>
							</>
						}
					/>
					<ListItemIcon edge="end" className={classes.mobileCategoryListDimensionIconItem}>
						<NavigateNext className={classes.mobileCategoryListDimensionIcon} />
					</ListItemIcon>
				</ListItem>
			</>
		);
	}

	function categoriesList() {
		var listItems = [];

		for (var categoryIndex in categories) {
			listItems.push(prepareCategory(categories[categoryIndex]));
		}

		return <>{listItems}</>;
	}

	function formatDimensionDataForRadialBarChart(dimension) {
		return [
			{
				name: 'Mastered',
				value: dimension.mastered.toFixed(0),
				fill: theme.palette.primary.light,
			},
			{
				name: 'Score',
				value: dimension.score.toFixed(0),
				fill: theme.palette.score.main,
			},
		];
	}

	return (
		<>
			<Grid container justify={'center'} xs={12}>
				<MobileHeader goBack={true} pageTitle={'Sub category'} />
				<MobileHeaderMargin />

				<Grid item xs={12} className={classes.mobileCategorySection1Title}>
					<span>{dimension.name} progression</span>
				</Grid>
				<Grid item xs={12} className={classes.mobileSection1SubTitle}>
					<span>Overview</span>
				</Grid>

				<Grid
					style={{
						minHeight: 100,
					}}
					item
					className={classes.halfCircle}
					xs={12}
				>
					<CustomRadialBarChart
						data={formatDimensionDataForRadialBarChart(dimension)}
						halfCircle={false}
					/>
				</Grid>

				<Grid item xs={12} className={classes.mobileCategorySection2Title}>
					<span>Progression by sub-category</span>
				</Grid>

				<Grid className={classes.mobileCategoryList} item xs={12}>
					{categoriesList()}
				</Grid>

				<Grid container xs={12} justify={'center'}>
					<MobileMenuMargin />
					<MobileMenu activePage="categories" />
				</Grid>
			</Grid>
		</>
	);
}
