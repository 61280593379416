import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
	card: {
		minHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	fullHeightBody: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}));
