import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { useTheme } from '@material-ui/styles';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Circular from '../../components/Loader/Circular';

// import useStyles from './styles';

import DashboardMobileVersion from './DashboardMobileVersion';
// import DashboardPcVersion from './DashboardPcVersion';

import { actions as authActions, selectors as authSelectors } from '../../services/auth';
import { initGraphData } from '../../services/callsUtils';

export default function Dashboard() {
	// var classes = useStyles();

	const dispatch = useDispatch();

	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const externalConfiguration = useSelector(authSelectors.getExternalConfiguration);
	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);
	const apiCallContext = useSelector(authSelectors.getApiCallContext);
	const tabsDataCollectTime = useSelector(authSelectors.getTabsDataCollectTime);

	const tabsData = useSelector(authSelectors.getTabsData);
	const selectedTab = useSelector(authSelectors.getSelectedTab);

	const init = async () => {
		try {
			setIsLoading(true);
			if (tabsData == null) {
				initGraphData(
					internalConfiguration.radar_chart_id,
					apiCallContext,
					dispatch,
					tabsDataCollectTime
				);
			} else {
				if (selectedTab == null) {
					dispatch(authActions.setSelectedTab(tabsData[0]));
				}
			}
			setIsLoading(false);
		} catch (e) {
			setError('(2) Some error occured: ' + e.detail);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (internalConfiguration.radar_chart_id) {
			init();
		}
	}, []);

	const ErrorCondition = ({ children }) => {
		return children;
	};

	return (
		<>
			<ErrorCondition
				widgetId={externalConfiguration.widget_id}
				token={externalConfiguration.token}
			>
				{isLoading && <Circular />}

				{error && (
					<Box mx={'auto'} width={1 / 2}>
						<Alert onClose={() => setError('')} severity={'error'}>
							{error}
						</Alert>
					</Box>
				)}
				{!error && (
					<>
						{/* <Box display={{ xs: 'none', md: 'block' }}>
							<DashboardPcVersion />
						</Box> */}
						{/* <Box xs={12} display={{ xs: 'block', md: 'none' }} style={{ width: '100%' }}> */}
						<Box xs={12} style={{ width: '100%' }}>
							<DashboardMobileVersion />
						</Box>
					</>
				)}
			</ErrorCondition>
		</>
	);
}
