import React from 'react';
import { Grid } from '@material-ui/core';

// import useStyles from './styles';

import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';

import TypeForm from '../../components/TypeForm';

export default function MobileTraining(props) {
	// var classes = useStyles();
	// var theme = useTheme();

	var state = props.location.state;
	var categoryTitle = '';
	var category = undefined;
	if (state && 'category' in state) {
		category = state.category;
		categoryTitle = category.label;
	}

	return (
		<>
			<Grid container justify={'center'} xs={12}>
				<MobileHeader goBack={true} pageTitle={'Training ' + categoryTitle} />
				<MobileHeaderMargin />

				<Grid item xs={12}>
					<TypeForm data={{ isEvaluation: false, category: category }} />
				</Grid>
			</Grid>
		</>
	);
}
