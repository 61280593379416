import { fetchWrapper } from './fetchWrapper';
import { actions as authActions } from './auth';
import { processAdditionalDataOnGraphData } from './calculateUtils';

const mapData = (data) =>
	data.map((row) => {
		return {
			name: row.name,
			color: row.color,
			categories: row.categories,
			score: row.score * 100,
			seen: (row.number_of_questions_seen / row.number_of_existing_questions) * 100,
			mastered: (row.number_of_questions_mastered / row.number_of_existing_questions) * 100,
		};
	});

export const collectGraphData = async (radarChartID, apiCallContext) => {
	const parameters = {
		1: radarChartID,
	};
	const response = await fetchWrapper.get('REACT_APP_GRAPH_API_URL', apiCallContext, parameters);
	const mappedData = mapData(response.dimensions);
	return mappedData;
};

export const initGraphData = async (radarChartID, apiCallContext, dispatch) => {
	var mappedData = await collectGraphData(radarChartID, apiCallContext);
	mappedData = processAdditionalDataOnGraphData(mappedData);

	var currentTime = new Date().getTime();
	var selectedTab = mappedData[0];

	dispatch(authActions.setInitTabsData({ mappedData, currentTime, selectedTab }));
};

export const refreshGraphData = async (
	radarChartID,
	apiCallContext,
	dispatch,
	lastGraphDataCollectTime
) => {
	var currentDate = new Date();
	var second = 1000;
	var secondsDelta = 30;
	var lastGraphDataCollectDateWithDelta = new Date(
		lastGraphDataCollectTime + secondsDelta * second
	);

	if (lastGraphDataCollectDateWithDelta < currentDate) {
		var mappedData = await collectGraphData(radarChartID, apiCallContext);
		mappedData = processAdditionalDataOnGraphData(mappedData);

		var currentTime = currentDate.getTime();

		dispatch(authActions.setTabsData({ mappedData, currentTime }));
	}
};

export const doLogout = async (dispatch, history) => {
	dispatch(authActions.logout());
	history.go(0);
};

export const refreshToken = async (dispatch, history, apiCallContext, accessToken) => {
	if (apiCallContext && accessToken.token) {
		var doRefresh = false;

		if (!accessToken.accessTokenLastUpdate || !accessToken.expires_at) {
			doRefresh = true;
		} else {
			var currentDate = new Date();
			var oneSecond = 1000;
			var dayDelta = 86400;
			var lastUpdateWithDelta = new Date(accessToken.accessTokenLastUpdate + dayDelta * oneSecond);

			if (lastUpdateWithDelta < currentDate) {
				doRefresh = true;
			}
		}

		if (doRefresh) {
			try {
				const response = await fetchWrapper.post(
					'REACT_APP_TOKEN_REFRESH',
					accessToken,
					apiCallContext
				);
				response.accessTokenLastUpdate = new Date().getTime();
				dispatch(authActions.setAccessToken(response));
			} catch (error) {
				doLogout(dispatch, history);
			}
		}
	}
};
