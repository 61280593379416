import React from 'react';

import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import useStyles from './styles';

// import { selectors as authSelectors } from '../../services/auth';

import HomeIcon from '@material-ui/icons/Home';
import Category from '@material-ui/icons/Category';
import BarChart from '@material-ui/icons/BarChart';
// import SettingsIcon from '@material-ui/icons/Settings';

export function MobileMenuMargin() {
	var classes = useStyles();

	return <span className={classes.mobileMenuMargin}> </span>;
}

const CustomBottomNavigation = withStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.default,
		position: 'fixed',
		bottom: '0',
	},
}))(BottomNavigation);

const CustomBottomNavigationAction = withStyles((theme) => ({
	root: {
		width: '100%',
		'&$selected': {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.background.defaultLight,
		},
	},
	wrapper: {},
	selected: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.defaultLight,
	},
	iconOnly: {
		color: theme.palette.background.defaultDark,
	},
	label: {
		color: theme.palette.primary.main,
	},
}))(BottomNavigationAction);

export function MobileMenu(props) {
	const history = useHistory();

	var activePage = props.activePage;
	const [value, setValue] = React.useState(activePage);

	// const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);

	const handleChange = (event, page) => {
		setValue(page);

		if (page == 'home') {
			history.push({
				pathname: '/',
			});
		} else if (page == 'categories') {
			history.push({
				pathname: '/mobile-dimension-list',
			});
		} else if (page == 'statistics') {
			history.push({
				pathname: '/mobile-statistics',
			});
		}
	};

	return (
		<>
			<CustomBottomNavigation value={value} onChange={handleChange}>
				<CustomBottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
				<CustomBottomNavigationAction label="Categories" value="categories" icon={<Category />} />
				<CustomBottomNavigationAction label="Statistics" value="statistics" icon={<BarChart />} />
			</CustomBottomNavigation>
		</>
	);
}
