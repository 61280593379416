import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import baseTheme from './themes';
import { Routing } from './routing';
import { GlobalStyle } from './global-style';
import ErrorBoundry from './components/Error/ErrorBoundry';
import { fetchWrapper } from './services/fetchWrapper';
import {
	defaultBG,
	defaultLBG,
	defaultLSecondaryBG,
	defaultTextPrimary,
	defaultTextSecondary,
	defaultOverPrimaryBackground,
	defaultOverSecondaryBackground,
	primary,
	secondary,
	tertiary,
	score,
} from './themes/default';
import { customizeTheme } from './helper';
import * as queryString from 'query-string';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { BoxCenter } from './components/Wrappers/Wrappers';
import Circular from './components/Loader/Circular';

import { actions as authActions, selectors as authSelectors } from './services/auth';

function App() {
	const [theme, setTheme] = useState(baseTheme.default);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const externalConfiguration = useSelector(authSelectors.getExternalConfiguration);

	const dispatch = useDispatch();

	var {
		primary_color,
		secondary_color,
		tertiary_color,
		score_color,
		background_color,
		light_background_color,
		secondary_background_color,
		border_radius,
		contrastText = '#FFF',
		text_primary,
		text_secondary,
		text_over_primary_background,
		text_over_secondary_background,
	} = queryString.parse(window.location.search);

	const init = async () => {
		setIsLoading(true);

		try {
			var widgetConfiguration = {};
			widgetConfiguration.externalConfiguration = queryString.parse(window.location.search);

			const context = {
				token: { token: widgetConfiguration.externalConfiguration.token },
				tenant_slug: widgetConfiguration.externalConfiguration.tenant_slug,
			};

			const parameters = { 1: widgetConfiguration.externalConfiguration.widget_id };
			const response = await fetchWrapper.get('REACT_APP_WIDGET_CONF_URL', context, parameters);
			widgetConfiguration.internalConfiguration = response;

			setIsLoading(false);
			dispatch(authActions.setInitWidgetConfiguration(widgetConfiguration));
		} catch (e) {
			setError('(1) Some error occured: ' + e.detail);
			setIsLoading(false);
		}
	};
	useEffect(() => {
		setTimeout(init, 100);
	}, []);

	useEffect(() => {
		primary_color = primary_color ? '#' + primary_color : primary;
		secondary_color = secondary_color ? '#' + secondary_color : secondary;
		tertiary_color = tertiary_color ? '#' + tertiary_color : tertiary;
		score_color = score_color ? '#' + score_color : score;
		background_color = background_color ? '#' + background_color : defaultBG;
		light_background_color = light_background_color ? '#' + light_background_color : defaultLBG;
		secondary_background_color = secondary_background_color
			? '#' + secondary_background_color
			: defaultLSecondaryBG;
		text_primary = text_primary ? '#' + text_primary : defaultTextPrimary;
		text_secondary = text_secondary ? '#' + text_secondary : defaultTextSecondary;
		text_over_primary_background = text_over_primary_background
			? '#' + text_over_primary_background
			: defaultOverPrimaryBackground;
		text_over_secondary_background = text_over_secondary_background
			? '#' + text_over_secondary_background
			: defaultOverSecondaryBackground;

		border_radius = border_radius ? parseInt(border_radius) : 4;
		const updatedTheme = customizeTheme(theme, {
			primary_color,
			secondary_color,
			tertiary_color,
			score_color,
			background_color,
			light_background_color,
			secondary_background_color,
			contrastText,
			border_radius,
			text_primary,
			text_secondary,
			text_over_primary_background,
			text_over_secondary_background,
		});
		setTheme(updatedTheme);
	}, []);

	// const ErrorCondition = ({ widgetId, token, tenant_slug, children }) => {
	const ErrorCondition = ({ widgetId, tenant_slug, children }) => {
		const AlertBox = (props) => (
			<Alert severity={'error'}>
				<BoxCenter>{props.children}</BoxCenter>
			</Alert>
		);
		if (!widgetId) {
			return <AlertBox>Widget id missing</AlertBox>;
		}
		// else if (!token) {
		// 	return <AlertBox>Access token not found</AlertBox>;
		// }
		else if (!tenant_slug) {
			return <AlertBox>tenant_slug not found</AlertBox>;
		} else {
			return children;
		}
	};
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStyle />
			<ErrorBoundry>
				<ErrorCondition
					widgetId={externalConfiguration.widget_id}
					// token={externalConfiguration.token}
					tenant_slug={externalConfiguration.tenant_slug}
				>
					{isLoading && <Circular />}
					{error && (
						<Box mx={'auto'} width={1 / 2}>
							<Alert onClose={() => setError('')} severity={'error'}>
								{error}
							</Alert>
						</Box>
					)}
					{!error && !isLoading && <Routing />}
				</ErrorCondition>
			</ErrorBoundry>
		</ThemeProvider>
	);
}

export default App;
