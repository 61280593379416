import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	mobileHeaderTitleContainerGrid: {
		// marginBottom: theme.spacing(4),
		paddingTop: '35px',
		height: '90px',
	},
	mobileHeaderBackButton: {},
	mobileHeaderBackIcon: {
		fontSize: 35,
	},
	mobileHeaderBackIconGrid: {},
	mobileHeaderTitleGrid: {
		textAlign: 'center',
	},
	mobilePageTitle: {},
	mobileHeaderMargin: {
		marginBottom: theme.spacing(12),
	},
}));
