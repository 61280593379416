import React from 'react';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { useSelector } from 'react-redux';

import { fetchWrapper } from '../../services/fetchWrapper';

import { selectors as authSelectors } from '../../services/auth';

import * as queryString from 'query-string';

import { Grid } from '@material-ui/core';

import { Box as MuiBox, Container as MuiContainer } from '@material-ui/core';

export default function EmailConfirmationPage() {
	const apiCallContext = useSelector(authSelectors.getApiCallContext);

	var { key } = queryString.parse(window.location.search);

	const [response, setResponse] = React.useState(null);

	// if (response == null || response == undefined ){
	//     // if(key){
	//         var res = sendEmailConfirmation(key)

	//         console.log("res:: ", typeof(res))

	//         res.then(value => {
	//             console.log("values : ", typeof(value))

	//             setResponse(value);
	//         }).catch(function(e) {
	//             console.log("e: ", typeof(e))
	//             console.log("e.message: ", typeof(e.message))
	//             setResponse(e.message);
	//         })

	//   }

	const timerRef = React.useRef();

	React.useEffect(
		() => () => {
			clearTimeout(timerRef.current);
		},
		[]
	);

	React.useEffect(() => {
		if (response == null || response == undefined) {
			// if(key){
			sendEmailConfirmation(key);
		}
	}, []);

	function sendEmailConfirmation(key) {
		const response = fetchWrapper.post(
			'EMAIL_CONFIRM_API_URL',
			{
				key: key,
			},
			apiCallContext
		);

		response
			.then((value) => {
				setResponse({ success: value });
			})
			.catch(function (e) {
				console.log(e);
				console.log(e.detail);
				setResponse({ error: e.detail });
			});

		// if (response) {
		//     console.log("IS NOT")
		//     setResponse(response);
		//     return response;
		// }
		// return response;

		// return new Promise((resolve, reject) => {
		//     axios.post(urlPrefix + `/api/v1/auth/register/verify-email/`,
		//     {
		//         key: "Mw:1kmIc3:Cptd50tMxYRG4QsPs5eNjUJjWzo",
		//     },
		//     )
		//         .then((response) => {
		//             let data = response.data
		//             resolve(data);
		//         })
		//         .catch((error) => {
		//             reject(error);
		//         });
		// });
	}

	return (
		<div>
			<MuiContainer style={{ paddingTop: '155px' }}>
				<MuiBox style={{ textAlign: 'center' }}>
					<Card>
						<CardHeader
							title="Email confirmation"
							// subheader="Users: 20"
						/>

						<CardContent>
							<Grid item xs={12}>
								<div>
									<Fade
										in={!response}
										style={{
											transitionDelay: response ? '0ms' : '800ms',
										}}
										unmountOnExit
									>
										<CircularProgress />
									</Fade>
								</div>

								<div>
									{response?.success ? (
										<h3>Nice catch ! Email confirmed</h3>
									) : (
										<h3>Oups ! An error occured</h3>
									)}
								</div>
							</Grid>
						</CardContent>
					</Card>
				</MuiBox>
			</MuiContainer>
		</div>
	);
}
