import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as authSelectors } from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { Typography } from '../../components/Wrappers/Wrappers';

import { refreshGraphData, refreshToken } from '../../services/callsUtils';

import { withStyles } from '@material-ui/core/styles';

import Linear from '../../components/Loader/LinearProgress';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';


import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';
import { MobileMenu, MobileMenuMargin } from '../../components/MobileMenu';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import NavigateNext from '@material-ui/icons/NavigateNext';


import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


// Tabs
function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
	  >
		{value === index && (
			<>
				{children}
			</>
		)}
   </div>
	);
  }

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}




// Accordeon

const Accordion = withStyles({
	root: {
	  boxShadow: 'none',
	  '&:not(:last-child)': {
		borderBottom: 0,
	  },
	  '&:before': {
		display: 'none',
	  },
	  '&$expanded': {
		margin: 'auto',
	  },
	},
	expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
	root: {
	  marginBottom: -1,
	  minHeight: 56,
	  '&$expanded': {
		minHeight: 56,
	  },
	},
	content: {
	  '&$expanded': {
		margin: '12px 0',
	  },
	},
	expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	},
  }))(MuiAccordionDetails);


  function CustomizedAccordions(innerElements, dimension, value) {
	const [expanded, setExpanded] = React.useState('');
	const theme = useTheme();
  
	const handleChange = (panel) => (event, newExpanded) => {
	  setExpanded(newExpanded ? panel : false);
	};
  
	return (
	  <div>
		<Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
			<AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
			style={{backgroundColor: theme.palette.background.default}}
			>
				<Grid container xs={12}>
					<Grid item xs={10}>
						<Typography 
							style={{
								marginBottom: '7px',
								fontWeight: '500',
								fontSize: '30px',
							}}
						>{dimension.name}</Typography>
						<Linear value={value} barColor={dimension.color} style={{width: '100%'}} />
					</Grid>
					<Grid item xs={2}
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						{(() => {
							if(expanded){
								return (
									<KeyboardArrowDownIcon style={{
										marginTop: '40px',
										fontSize: '40px',
									}}/>
								)

							}else{
								return(
									<NavigateNext style={{
										marginTop: '40px',
										fontSize: '40px',
									}}/>
								)
							}
						
						})()}
					</Grid>
				</Grid>

			</AccordionSummary>
			<AccordionDetails
				style={{backgroundColor: theme.palette.background.default}}
			>
				{innerElements}
			</AccordionDetails>
		</Accordion>
	  </div>
	);
  }



export default function MobileStatistics() {
	// var classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();

	const internalConfiguration = useSelector(authSelectors.getInternalConfiguration);
	const apiCallContext = useSelector(authSelectors.getApiCallContext);
	const tabsDataCollectTime = useSelector(authSelectors.getTabsDataCollectTime);
	const accessToken = useSelector(authSelectors.getAccessToken);

	const tabsData = useSelector(authSelectors.getTabsData);

	const [tabValue, setTabValue] = React.useState(0);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleTabChangeIndex = (index) => {
		setTabValue(index);
	};

	function handleCategoryClick(category, dimension) {
		history.push({
			pathname: '/mobile-category-detail/',
			state: {
				category: category,
				dimension: dimension,
			},
		});
	}

	useEffect(() => {
		refreshGraphData(
			internalConfiguration.radar_chart_id,
			apiCallContext,
			dispatch,
			tabsDataCollectTime
		);
		if (internalConfiguration.is_loggin_functionality_required) {
			refreshToken(dispatch, history, apiCallContext, accessToken, internalConfiguration);
		}
	}, []);

	function calculateLinearProgressValue(type, category){
		if(type == 'score'){
			return category.score
		} else if (type == 'mastered'){
			return category.mastered
		}
	}

	function buildCategoryLinearProgress(category, dimension, type){
		var value = calculateLinearProgressValue(type, category)
		return (
			<Grid key={category.id}  container xs={12}
				style={{
					marginBottom: '35px',
				}}
				onClick={() => handleCategoryClick(category, dimension)}
			>
				<Grid item xs={1}>
				</Grid>

				<Grid item xs={11}>
					<Typography 
						style={{
							marginBottom: '3px',
							fontWeight: '450',
						}}
					>
						{category.label}
					</Typography>
					<Linear value={value} barColor={dimension.color}/>
				</Grid>
			</Grid>
		)
	}

	function buildListCategoryLinearProgress(dimension, type){
		var categoryList = []
		for (let categoryIndex in dimension.categories){
			var category = dimension.categories[categoryIndex]
			categoryList.push(buildCategoryLinearProgress(category, dimension, type))
		} 

		var wrapedCategoryList = [
			<Grid container xs={12}>
				{categoryList}
			</Grid>
		]

		var value = null
		if (type == 'score'){
			value = dimension.score
		}else if(type == 'mastered'){
			value = dimension.mastered
		}

		return(CustomizedAccordions(wrapedCategoryList, dimension, value))
	}

	function buildDimensionProgress(dimension, type){
		return(
			<Grid key={dimension.id} item xs={12}>
				{buildListCategoryLinearProgress(dimension, type)}
			</Grid>
		)
	}

	function GraphList({type}) {
		var accordeonList = []

		for (let dimensionIndex in tabsData){
			var dimension = tabsData[dimensionIndex]
			accordeonList.push(buildDimensionProgress(dimension, type))
		}
		return accordeonList
	}

	return (
		<>
			<Grid container justify={'center'} xs={12}>
				<MobileHeader goBack={true} pageTitle="Statistics" />
				<MobileHeaderMargin />

				<AppBar color="default"
					style={{
						marginTop: '90px',
					}}
				>
					<Tabs
					value={tabValue}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
					>
					<Tab label="Scores" {...a11yProps(0)} 
					/>
					<Tab label="Mastery" {...a11yProps(1)} />
					</Tabs>
				</AppBar>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tabValue}
					onChangeIndex={handleTabChangeIndex}
					style={{
						width: '100%',
						padding: '0',
						overflowX: "visible important!",
						overflow: "visible important!",
						marginTop: '25px',

					}}
					slideStyle={{ overflow: 'visible'}}
				>
					<TabPanel value={tabValue} index={0} dir={theme.direction}
						style={{
							width: '100%',
							padding: '0',
							overflowX: "visible",
						}}
					>
						<Grid
							container
							xs={12}
						>
							<GraphList type={'score'}/>
						</Grid>
					</TabPanel>
					<TabPanel value={tabValue} index={1} dir={theme.direction}>
						<Grid
							container
							xs={12}
						>
							<GraphList type={'mastered'}/>
						</Grid>
					</TabPanel>
				</SwipeableViews>


				<Grid container xs={12} justify={'center'}>
					<MobileMenuMargin />
					<MobileMenu activePage="statistics" />
				</Grid>
			</Grid>
		</>
	);
}
