import React from 'react';
import { Grid } from '@material-ui/core';

// import useStyles from './styles';

import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';

import TypeForm from '../../components/TypeForm';

export default function MobileEvaluation(props) {
	// var classes = useStyles();

	var category = props.location.state.category;

	return (
		<>
			<Grid container justify={'center'} xs={12}>
				<MobileHeader goBack={true} pageTitle={'Evaluation ' + category.label} />
				<MobileHeaderMargin />

				<Grid item xs={12}>
					<TypeForm data={{ isEvaluation: true, category: category }} />
				</Grid>
			</Grid>
		</>
	);
}
