import React from 'react';
import { Grid } from '@material-ui/core';

import useStyles from './styles';
import { useTheme } from '@material-ui/core';
import { MobileHeader, MobileHeaderMargin } from '../../components/MobileHeader';
import { MobileMenu, MobileMenuMargin } from '../../components/MobileMenu';

import { CustomRadialBarChart } from '../../components/Charts/CustomRadialBarChart';
import CustomLineChart from '../../components/Charts/CustomLineChart';

import { BoxCenter } from '../../components/Wrappers/Wrappers';

import Button from '@material-ui/core/Button';

import { useHistory } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import School from '@material-ui/icons/School';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import StarIcon from '@material-ui/icons/Star';

import Avatar from '@material-ui/core/Avatar';

export default function MobileCategoryDetail(props) {
	var classes = useStyles();
	var theme = useTheme();

	const history = useHistory();

	const dataLineChart = [
		{
			name: '02/06',
			line1: 200,
			line2: 20,
		},
		{
			name: '04/06',
			line1: 220,
			line2: 30,
		},
		{
			name: '06/06',
			line1: 220,
			line2: 50,
		},
		{
			name: '08/06',
			line1: 220,
			line2: 70,
		},
		{
			name: '10/06',
			line1: 240,
			line2: 80,
		},
		{
			name: '14/06',
			line1: 240,
			line2: 100,
		},
		{
			name: '16/06',
			line1: 240,
			line2: 110,
		},
	];

	var category = props.location.state.category;
	// var dimension = props.location.state.dimension;

	function handleSessionButtonClick(mode) {
		if (mode == 'training') {
			history.push({
				pathname: '/mobile-training/',
				state: {
					category: category,
				},
			});
		} else {
			history.push({
				pathname: '/mobile-evaluation/',
				state: {
					category: category,
				},
			});
		}
	}

	function formatEvaluationReadynessDataForRadialBarChart(category) {
		return [
			{
				name: 'Exam preparation /100',
				value: category.evaluationReadiness,
				fill: theme.palette.primary.light,
			},
		];
	}

	return (
		<>
			<Grid container justify={'center'} xs={12}>
				<MobileHeader goBack={true} pageTitle={category.label} />

				<Grid container xs={12} className={classes.mobileSection1}>
					<MobileHeaderMargin />
					<Grid container xs={12}>
						<Grid container xs={7}>
							<Grid item xs={12} className={classes.mobileSection1Title}>
								<span>Training progression</span>
							</Grid>
							<Grid item xs={12} className={classes.mobileSection1SubTitle}>
								<span>Unlock next exam</span>
							</Grid>

							<Grid
								style={{
									minHeight: 300,
								}}
								item
								xs={12}
								className={classes.halfCircle}
							>
								<CustomRadialBarChart
									data={formatEvaluationReadynessDataForRadialBarChart(category)}
									halfCircle={true}
								/>
							</Grid>
						</Grid>
						<Grid container xs={5}>
							<Grid item xs={12}>
								<Avatar
									alt={category.label}
									src="/static/images/avatar/1.jpg"
									style={{
										width: '300px',
										height: '300px',
										marginTop: '-30px',
										fontSize: '50px',
										backgroundColor: theme.palette.primary.main,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} className={classes.mobileDetailListBorderMargin}>
						<ListItem
							alignItems="flex-start"
							className={classes.mobileCategoryDetailListElementHowTo}
						>
							<ListItemIcon
								edge="start"
								className={classes.mobileCategoryDetailListElementIconItem}
							>
								<LocalLibrary className={classes.mobileCategoryDetailListElementIconHowTo} />
							</ListItemIcon>

							<ListItemText
								primary={
									<Grid container className={classes.mobileHowToContainer}>
										<span className={classes.mobileDiscoverTitle}>HOW TO USE</span>
									</Grid>
								}
							/>
						</ListItem>
					</Grid>
				</Grid>

				<Grid container xs={12} className={classes.mobileSection2}>
					<Grid item xs={12} className={classes.mobileSection2Title}>
						<span>Progression statistics</span>
					</Grid>
					<Grid item xs={12} className={classes.mobileSection2SubTitle}>
						<span>Track your success</span>
					</Grid>

					<Grid item xs={12} className={classes.mobileDetailListBorderMargin}>
						<ListItem
							alignItems="flex-start"
							className={classes.mobileCategoryDetailListElementDiscovered}
						>
							<ListItemIcon
								edge="start"
								className={classes.mobileCategoryDetailListElementIconItem}
							>
								<LocalLibrary className={classes.mobileCategoryDetailListElementIcon} />
							</ListItemIcon>

							<ListItemText
								primary={
									<Grid container className={classes.mobileDiscoverContainer}>
										<Grid item xs={5}>
											<span className={classes.mobileDiscoverTitle}>Discovered</span>
										</Grid>
										<Grid item xs={7}>
											<span className={classes.mobileDiscoverProgress}>
												{category.discovery} {'%'}
											</span>
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					</Grid>

					<Grid item xs={12} className={classes.mobileDetailListBorderMargin}>
						<ListItem
							alignItems="flex-start"
							className={classes.mobileCategoryDetailListElementMastered}
						>
							<ListItemIcon
								edge="start"
								className={classes.mobileCategoryDetailListElementIconItem}
							>
								<School className={classes.mobileCategoryDetailListElementIcon} />
							</ListItemIcon>

							<ListItemText
								primary={
									<Grid container className={classes.mobileDiscoverContainer}>
										<Grid item xs={5}>
											<span className={classes.mobileDiscoverTitle}>Mastered</span>
										</Grid>
										<Grid item xs={7}>
											<span className={classes.mobileDiscoverProgress}>
												{category.mastered} {'%'}
											</span>
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					</Grid>
					<Grid item xs={12} className={classes.mobileDetailListBorderMargin}>
						<ListItem
							alignItems="flex-start"
							className={classes.mobileCategoryDetailListElementScore}
						>
							<ListItemIcon
								edge="start"
								className={classes.mobileCategoryDetailListElementIconItem}
							>
								<StarIcon className={classes.mobileCategoryDetailListElementIcon} />
							</ListItemIcon>

							<ListItemText
								primary={
									<Grid container className={classes.mobileDiscoverContainer}>
										<Grid item xs={5}>
											<span className={classes.mobileDiscoverTitle}>Score</span>
										</Grid>
										<Grid item xs={7}>
											<span className={classes.mobileDiscoverProgress}>
												{category.score} {'%'}
											</span>
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					</Grid>

					<Grid item xs={12} className={classes.mobileSectionGraph}>
						<span className={classes.mobileSectionGraphTitle}>Mastered</span>
						<CustomLineChart
							data={dataLineChart}
							name1="Total"
							name2="Mastered"
							className={classes.mobileSectionGraphBody}
						/>
					</Grid>

					<Grid item xs={12} className={classes.mobileStartSession}>
						<BoxCenter>
							{(() => {
								if (category.last_evaluation_date == null) {
									return (
										<Button
											onClick={() => handleSessionButtonClick()}
											variant={'contained'}
											className={classes.mobileStartSessionButton}
											style={{ color: theme.palette.primary.main }}
										>
											Do your first evaluation ;)
										</Button>
									);
								} else if (category.evaluationReadiness >= 100) {
									return (
										<Button
											onClick={() => handleSessionButtonClick()}
											variant={'contained'}
											className={classes.mobileStartSessionButton}
											style={{ color: theme.palette.primary.main }}
										>
											Start exam
										</Button>
									);
								} else {
									return (
										<Button
											onClick={() => handleSessionButtonClick('training')}
											variant={'contained'}
											className={classes.mobileStartSessionButton}
											style={{ color: theme.palette.primary.main }}
										>
											Start training session
										</Button>
									);
								}
							})()}
						</BoxCenter>
					</Grid>
				</Grid>

				<Grid container xs={12} justify={'center'}>
					<MobileMenuMargin />
					<MobileMenu activePage="home" />
				</Grid>
			</Grid>
		</>
	);
}
