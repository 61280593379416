import React, { Component } from 'react';
import Box from '@material-ui/core/Box';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo,
		});
	}

	render() {
		if (this.state.error) {
			// You can render any custom fallback UI
			return (
				<Box>
					Something went wrong
					<details style={{ whiteSpace: 'pre-wrap' }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</Box>
			);
		}

		return this.props.children;
	}
}
