import { Action, combineReducers } from 'redux';

import { actions as authActions, reducer as authReducer } from '../services/auth';
import { reducer as widgetDataReducer } from '../services/auth';

const combinedRootReducer = combineReducers({
  auth: authReducer,
  widgetData: widgetDataReducer
});

function rootReducer(state: RootState, action: Action) {
  if (action.type === authActions.logout.toString()) {
    state = undefined as unknown as RootState
    // state = undefined as RootState
    localStorage.removeItem('persist:auth');
    sessionStorage.clear();
  }

  return combinedRootReducer(state, action);
}

export default rootReducer;

export type RootState = ReturnType<typeof combinedRootReducer>
