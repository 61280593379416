import tinycolor from 'tinycolor2';

export const lightenRate = 20;
export const darkenRate = 20;

export const customizeTheme = (
	theme,
	{
		border_radius,
		background_color,
		light_background_color,
		secondary_background_color,
		primary_color,
		contrastText,
		secondary_color,
		tertiary_color,
		score_color,
		text_primary,
		text_secondary,
		text_over_primary_background,
		text_over_secondary_background,
	}
) => {
	const updatedTheme = {
		...theme,
		overrides: {
			...theme.overrides,
			MuiPaper: {
				rounded: {
					borderRadius: border_radius,
				},
			},
		},
		globalBorderRadius: border_radius,
		palette: {
			...theme.palette,
			primary: {
				main: primary_color,
				light: tinycolor(primary_color).lighten(lightenRate).toHexString(),
				dark: tinycolor(primary_color).darken(darkenRate).toHexString(),
				contrastText,
			},
			secondary: {
				main: secondary_color,
				light: tinycolor(secondary_color).lighten(lightenRate).toHexString(),
				dark: tinycolor(secondary_color).darken(darkenRate).toHexString(),
				contrastText: '#fff',
			},
			tertiary: {
				main: tertiary_color,
				light: tinycolor(tertiary_color).lighten(lightenRate).toHexString(),
				dark: tinycolor(tertiary_color).darken(darkenRate).toHexString(),
				contrastText,
			},
			score: {
				main: score_color,
				light: tinycolor(score_color).lighten(lightenRate).toHexString(),
				dark: tinycolor(score_color).darken(darkenRate).toHexString(),
				contrastText,
			},
			background: {
				...theme.palette.background,
				default: background_color,
				defaultDark: tinycolor(background_color).darken(darkenRate).toHexString(),
				defaultLight: tinycolor(background_color).lighten(lightenRate).toHexString(),
				light: light_background_color,
				secondary: secondary_background_color,
			},
			text: {
				...theme.palette.text,
				primary: text_primary,
				secondary: text_secondary,
				overPrimaryBackground: text_over_primary_background,
				overSecondaryBackground: text_over_secondary_background,
			},
		},
	};
	return updatedTheme;
};
