import { makeStyles } from '@material-ui/styles';

import tinycolor from 'tinycolor2';

export default makeStyles((theme) => ({
	card: {
		minHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	visitsNumberContainer: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		paddingBottom: theme.spacing(1),
	},
	progressSection: {
		marginBottom: theme.spacing(1),
	},
	progressTitle: {
		marginBottom: theme.spacing(2),
	},
	progress: {
		marginBottom: theme.spacing(1),
		backgroundColor: 'rgb(236, 236, 236)',
	},
	pieChartLegendWrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end',
		marginRight: theme.spacing(1),
	},
	legendItemContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(1),
	},
	fullHeightBody: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	tableWidget: {
		overflowX: 'auto',
	},
	progressBarPrimary: {
		backgroundColor: theme.palette.primary.main,
	},
	progressBarWarning: {
		backgroundColor: theme.palette.warning.main,
	},
	performanceLegendWrapper: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		marginBottom: theme.spacing(1),
	},
	legendElement: {
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(2),
	},
	legendElementText: {
		marginLeft: theme.spacing(1),
	},
	serverOverviewElement: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '100%',
	},
	serverOverviewElementText: {
		minWidth: 145,
		paddingRight: theme.spacing(2),
	},
	serverOverviewElementChartWrapper: {
		width: '100%',
	},
	mainChartBody: {
		overflowX: 'auto',
	},
	mainChartHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.only('xs')]: {
			flexWrap: 'wrap',
		},
	},
	mainChartHeaderLabels: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.only('xs')]: {
			order: 3,
			width: '100%',
			justifyContent: 'center',
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(2),
		},
	},
	mainChartHeaderLabel: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: theme.spacing(3),
	},
	mainChartSelectRoot: {
		borderColor: theme.palette.text.hint + '80 !important',
	},
	mainChartSelect: {
		padding: 10,
		paddingRight: 25,
	},
	mainChartLegentElement: {
		fontSize: '18px !important',
		marginLeft: theme.spacing(1),
	},
	mobileMenuContainer: {
		marginTop: '50px',
	},
	mobileMenu: {},
	mobileMenuButton: {
		textAlign: 'left',
		height: '60px',
		marginTop: '13px',
		marginBottom: '12px',
		borderRadius: theme.globalBorderRadius,
		width: '100%',
		backgroundColor: theme.palette.background.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		boxShadow: theme.customShadows.widgetDark,
	},
	mobileMenuButtonIconItem: {
		marginLeft: '-20px',
		// marginTop: '-15px',
	},
	mobileMenuButtonIcon: {
		marginTop: '-15px',
		padding: '10px',
		borderRadius: 100,
		color: theme.palette.background.light,
		backgroundColor: theme.palette.primary.main,
		fontSize: '65px',
	},
	mobileMenuButtonTitleContainer: {
		marginTop: '8px',
		marginLeft: '20px',
		color: theme.palette.primary.main,
	},
	mobileMenuButtonTitle: {
		fontWeight: 500,
		fontSize: '20px',
	},
	endPageMargin: {
		marginBottom: '50px',
	},

	// Mobile IA block

	mobileHomeIAIContainer: {
		width: '100%',
	},
	mobileHomeIAItem: {
		borderRadius: theme.globalBorderRadius,
		background:
			'linear-gradient(to left top, ' +
			theme.palette.primary.main +
			', ' +
			theme.palette.primary.light +
			')',
		height: '375px',
		marginRight: '10px',
		marginLeft: '10px',
		overflow: 'hidden',
		boxShadow: theme.customShadows.widget,
	},
	mobileHomeIAItem1: {
		height: '75%',
	},
	mobileHomeIAItem1Left: {
		paddingLeft: '30px',
		paddingTop: '32%',
		color: theme.palette.background.light,
		fontSize: '26px',
		fontWeight: 500,
	},
	mobileHomeIAItem1Right: {},

	mobileHomeIAItem2: {
		height: '25%',
	},
	mobileHomeAIItem2Wording: {
		marginLeft: '30px',
		fontSize: '20px',
		fontWeight: 500,
		color: theme.palette.background.light,
	},
	mobileHomeAIItem2CTA: {
		borderRadius: theme.globalBorderRadius,
		backgroundColor: theme.palette.background.light,
		color: theme.palette.primary.main,
	},
	mobileHomeIAImageSurround: {
		minHeight: '250px',
		backgroundColor: theme.palette.primary.main,
	},
	mobileHomeIAImage: {
		height: '275px',
		width: '275px',
		fontSize: '50px',
		borderRadius: '100%',
		position: 'relative',
		right: '-35%',
		top: '-5%',
		backgroundColor: theme.palette.primary.main,
	},

	// Categ of the day

	mobileHomeCategOfDayIContainer: {
		width: '100%',
	},
	mobileHomeCategOfDayItem: {
		borderRadius: theme.globalBorderRadius,
		height: '375px',
		marginRight: '10px',
		marginLeft: '10px',
		marginTop: '10px',
		marginBottom: '10px',
		overflow: 'hidden',
		boxShadow: theme.customShadows.widgetDark,
	},
	mobileHomeCategOfDayItem1: {
		background:
			'linear-gradient(to left top, ' +
			theme.palette.tertiary.main +
			' , ' +
			theme.palette.tertiary.light +
			')',
		height: '75%',
	},
	mobileHomeCategOfDayItem1Left: {
		paddingLeft: '30px',
		paddingTop: '200px',
		// paddingTop: '32%',
		color: theme.palette.background.light,
		fontSize: '23px',
		fontWeight: 500,
	},
	mobileHomeCategOfDayItem1Right: {},
	mobileHomeCategOfDayItem2: {
		paddingTop: '20px',
		height: '25%',
		backgroundColor: theme.palette.background.light,
	},
	mobileHomeCategOfDayItem2Title: {
		marginLeft: '30px',
		fontSize: '18px',
		color: theme.palette.tertiary.main,
	},
	mobileHomeCategOfDayItem2Wording: {
		marginLeft: '30px',
		fontSize: '20px',
		fontWeight: 500,
		color: theme.palette.background.dark,
	},
	mobileHomeCategOfDayItem2CTA: {
		borderRadius: theme.globalBorderRadius,
		color: theme.palette.background.light,
		backgroundColor: theme.palette.tertiary.main,
	},
	mobileHomeCategOfDayImageSurround: {
		minHeight: '250px',
		backgroundColor: theme.palette.primary.main,
	},
	mobileHomeCategOfDayImage: {
		height: '275px',
		width: '275px',
		fontSize: '50px',
		borderRadius: '100%',
		position: 'relative',
		right: '-35%',
		top: '-5%',
		backgroundColor: theme.palette.tertiary.main,
	},

	// Eval

	mobileHomeEvalContainer: {
		width: '100%',
	},
	mobileHomeEvalItem: {
		borderRadius: theme.globalBorderRadius,
		height: '150px',
		marginRight: '10px',
		marginLeft: '10px',
		marginTop: '10px',
		marginBottom: '10px',
		background:
			'linear-gradient(to left, ' +
			tinycolor(theme.palette.score.main).darken(12).toHexString() +
			' , ' +
			theme.palette.score.main +
			')',
		boxShadow: theme.customShadows.widgetDark,
	},
	mobileHomeEvalItemLeft: {},
	mobileHomeEvalmage: {
		height: '130px',
		width: '130px',
		borderRadius: '100%',
		position: 'relative',
		top: '15px',
		left: '20px',
	},
	mobileHomeEvalItemRight: {},

	mobileHomeEvalItemTitle: {
		paddingLeft: '10px',
		marginTop: '20px',

		color: theme.palette.background.light,
		fontSize: '18px',
		fontWeight: 500,
	},
	mobileHomeEvalItemWording: {
		paddingLeft: '10px',
		marginTop: '15px',
		color: theme.palette.background.light,
		fontSize: '15px',
	},
	mobileHomeEvalItemCTA: {
		paddingLeft: '15px',
		marginTop: '15px',
		color: theme.palette.background.light,
		fontSize: '18px',
	},
}));
