import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import './styles.css';

export const SubCategList = ({ dimension }) => {
	const history = useHistory();

	function handleCategoryClick(category) {
		history.push({
			pathname: '/mobile-category-detail/',
			state: {
				category: category,
				dimension: dimension,
			},
		});
	}

	function prepareCategory(category) {
		return (
			<>
				<Grid container xs={4} className="carousel-container">
					<Grid item xs={12} className="carousel-card-grid">
						<div className="carousel-card" onClick={() => handleCategoryClick(category)}>
							<Avatar
								alt={category.label}
								src="/static/images/avatar/1.jpg"
								style={{
									width: '100px',
									height: '100px',
									fontSize: '50px',
									backgroundColor: dimension.color,
									marginBottom: '-20px',
									marginTop: '-10px',
								}}
							/>
						</div>
					</Grid>

					<Grid item xs={12} className="carousel-card-bellow-grid">
						<div className="carousel-title">{category.label}</div>
					</Grid>
				</Grid>
			</>
		);
	}

	function categoriesList() {
		var listItems = [];

		for (var categoryIndex in dimension.categories) {
			listItems.push(prepareCategory(dimension.categories[categoryIndex]));
		}

		return <>{listItems}</>;
	}

	return (
		<>
			<Grid container xs={12} className="carousel-grid-container">
				{categoriesList()}
			</Grid>
		</>
	);
};
